import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import logo from '../../../assets/logo.svg';
import logoWhite from '../../../assets/logoWhite.svg';

const Logo = styled.img`
  height: ${props => props.size};
`;

const AppLogo = ({
  size,
  style,
  white,
  className,
}) => {
  return (
    <Logo src={white ? logoWhite : logo} className={className} alt="logo" size={size} style={style} />
  );
};

AppLogo.propTypes = {
  size: PropTypes.string,
  style: PropTypes.object,
  white: PropTypes.bool,
  className: PropTypes.string,
};

AppLogo.defaultProps = {
  size: '25px',
  style: {},
  white: false,
  className: '',
};

export default AppLogo;
