export default (state = {
  requestCounter: 0,
  isFetching: false,
}, action) => {
  const { type } = action;
  if (type.includes('FETCH')) {
    if (type.includes('_REQUEST')) {
      const requestCounter = state.requestCounter + 1;
      return {
        requestCounter,
        isFetching: requestCounter > 0,
      };
    } if (
      type.includes('_SUCCESS')
      || type.includes('_FAILURE')
      || type.includes('_TIMEOUT')
    ) {
      const requestCounter = state.requestCounter - 1;
      return {
        requestCounter,
        isFetching: requestCounter > 0,
      };
    }
  }
  return state;
};
