import {
  SELECT_PROJECT,
  SELECT_BOARD,
  SELECT_BOARD_GANTT,
  SELECT_TASK,
  UNSELECT_ALL,
  TOGGLE_DROPDOWN_SETTING,
  TOGGLE_BOARD_SEARCH,
  USER_SEARCH,
  CHANGE_LANGUAGE,
} from '../constants/user';

import { TOGGLE_CREATE_MODAL_BOARD } from '../constants/board';

export default (state = {
  selectedProjectId: '',
  selectedBoardId: '',
  selectedTaskId: '',
  showDropDownSetting: false,
  searchActive: false,
  search: '',
  language: 'en',
}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SELECT_PROJECT: {
      const { body } = payload;
      const { id } = body;
      return {
        ...state,
        selectedProjectId: id,
        selectedBoardId: '',
      };
    }
    case SELECT_BOARD: {
      const { body } = payload;
      let { id } = body;
      if (state.selectedBoardId === id) {
        id = '';
      }
      return {
        ...state,
        selectedBoardId: id,
      };
    }
    case SELECT_BOARD_GANTT: {
      const { body } = payload;
      let { id } = body;
      return {
        ...state,
        selectedBoardId: id,
      };
    }
    case SELECT_TASK: {
      const { body } = payload;
      let { id } = body;
      if (state.selectedTaskId === id) {
        id = '';
      }
      return {
        ...state,
        selectedTaskId: id,
      };
    }
    case TOGGLE_BOARD_SEARCH: {
      const { body } = payload;
      let { active } = body;
      if (active === undefined) {
        active = !state.searchActive;
      }
      return {
        ...state,
        searchActive: active,
      };
    }
    case UNSELECT_ALL: {
      return {
        ...state,
        selectedProjectId: '',
        selectedBoardId: '',
        selectedTaskId: '',
      };
    }
    case TOGGLE_CREATE_MODAL_BOARD: {
      return {
        ...state,
        selectedBoardId: '',
      };
    }
    case TOGGLE_DROPDOWN_SETTING: {
      const showDropDownSetting = !state.showDropDownSetting;
      return {
        ...state,
        showDropDownSetting,
      };
    }
    case USER_SEARCH: {
      return {
        ...state,
        search: payload.body.text ? payload.body.text : '',
      };
    }
    case CHANGE_LANGUAGE: {
      return {
        ...state,
        language: payload.language,
      };
    }
    default: {
      return state;
    }
  }
};
