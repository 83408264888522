import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Cookies from 'universal-cookie';
import { changeLanguage } from '../../actions/user';

const options = [
  { value: 'en', label: 'EN' },
  { value: 'es', label: 'ES' },
];

const customStyles = {
  option: provided => ({
    ...provided,
    color: 'white',
    background: 'transparent',
    fontFamily: 'Roboto,arial,sans-serif',
  }),
  menu: provided => ({
    ...provided,
    background: '#2a3d41',
  }),
  singleValue: provided => ({
    ...provided,
    color: 'white',
    fontFamily: 'Roboto,arial,sans-serif',
  }),
  control: provided => ({
    ...provided,
    background: 'transparent',
    border: 'transparent',
  }),
  placeholder: provided => ({
    ...provided,
    color: 'white',
    fontFamily: 'Roboto,arial,sans-serif',
  }),
};

class LanguageSelector extends Component {
  constructor(props) {
    super(props);
    const cookies = new Cookies().get('language');
    if (cookies) {
      this.props.changeLanguage(cookies);
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(selectedLanguage) {
    this.props.changeLanguage(selectedLanguage.value);
    new Cookies().set('language', selectedLanguage.value, { path: '/' });
  }

  render() {
    let placeholder;
    options.forEach((item) => {
      if (item.value === this.props.selectedLanguage) {
        placeholder = item.label;
      }
    });
    return (
      <Select
        onChange={this.handleChange}
        placeholder={placeholder}
        options={options}
        isSearchable={false}
        styles={customStyles}
      />
    );
  }
}

LanguageSelector.propTypes = {
  changeLanguage: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    selectedLanguage: state.user.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    changeLanguage,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
