import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ChatbotService from '../chatbot/ChatbotService';

import Login from '../components/login/LoginContainer';

const PrivateComponent = ({ id, isAuthenticated, children }) => (
  <Fragment>
    {
      isAuthenticated ? (
        <Fragment>
          <ChatbotService />
          {/* <df-messenger
            chat-icon={require('../assets/images/isotipo_normal.jpeg').default}
            intent="WELCOME"
            chat-title="ZmartBot"
            agent-id="6e5180cd-9620-4ed0-8359-2459a8d40e88"
            language-code="es"
            user-id={id}
          /> */}
          {children}
        </Fragment>
      ) : (
        <Login />
      )
    }
  </Fragment>
);

PrivateComponent.propTypes = {
  id: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => {
  return {
    id: state.auth.id,
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateComponent);
