import { attr, Model } from 'redux-orm';

import {
  FETCH_CREATE_FOLDER,
  FETCH_UPDATE_FOLDER,
  FETCH_DELETE_FOLDER,
  FETCH_GET_FOLDER,
  FETCH_GET_ALL_FOLDERS,
} from '../../constants/api/folders';

class Folder extends Model {
  static create(props) {
    if (props.hasOwnProperty(this.idAttribute) && this.idExists(props[this.idAttribute])) {
      const model = this.withId(props.id);
      return model.update(props);
    }
    return super.create(props);
  }

  static reducer(action, modelClass) {
    const { type, payload } = action;
    switch (type) {
      case `${FETCH_CREATE_FOLDER}_SUCCESS`:
        modelClass.create(payload.body);
        break;
      case `${FETCH_UPDATE_FOLDER}_SUCCESS`:
        modelClass.withId(payload.body.id).update(payload.body);
        break;
      case `${FETCH_DELETE_FOLDER}_SUCCESS`:
        modelClass.withId(payload.body.id).delete();
        break;
      case `${FETCH_GET_FOLDER}_SUCCESS`:
        modelClass.create(payload.body);
        break;
      case `${FETCH_GET_ALL_FOLDERS}_SUCCESS`: {
        const array = payload.body.results || payload.body;
        for (let i = 0; i < array.length; i += 1) {
          const obj = array[i];
          modelClass.create(obj);
        }
        break;
      }
      default:
        break;
    }
    return null;
  }
}

Folder.modelName = 'Folder';

Folder.fields = {
  id: attr(),
  name: attr(),
};

export default Folder;
