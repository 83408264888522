import {
  SAVE_FOLDER,
  SAVE_PROJECT,
  SET_FIRST_TIME,
} from '../constants/welcome';

export default (state = {
  folderName: '',
  projectName: '',
  projectId: '',
  folder: false,
  project: false,
  firstTime: false,
}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SAVE_FOLDER: {
      return {
        ...state,
        folderName: payload.body.name,
        folder: true,
      };
    }
    case SAVE_PROJECT: {
      return {
        ...state,
        firstTime: true,
        projectId: payload.body.id,
        projectName: payload.body.name,
        project: true,
      };
    }
    case SET_FIRST_TIME: {
      return {
        ...state,
        firstTime: payload.body.firstTime,
      };
    }
    default: {
      return state;
    }
  }
};
