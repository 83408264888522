import { attr, Model } from 'redux-orm';

import {
  FETCH_ALL_USERS,
  FETCH_MAX_PROJECTS,
  FETCH_ADD_ADMIN,
  FETCH_DELETE_ADMIN,
} from '../../constants/api/users';

class User extends Model {
  static create(props) {
    if (props.hasOwnProperty(this.idAttribute) && this.idExists(props[this.idAttribute])) {
      const model = this.withId(props.id);
      return model.update(props);
    }
    return super.create(props);
  }

  static reducer(action, modelClass) {
    const { type, payload } = action;
    switch (type) {
      case `${FETCH_MAX_PROJECTS}_SUCCESS`:
        modelClass.withId(payload.body.id).update(payload.body);
        break;
      case `${FETCH_ADD_ADMIN}_SUCCESS`:
        modelClass.withId(payload.body.id).update(payload.body);
        break;
      case `${FETCH_DELETE_ADMIN}_SUCCESS`:
        modelClass.withId(payload.body.id).update(payload.body);
        break;
      case `${FETCH_ALL_USERS}_SUCCESS`: {
        const array = payload.body;
        for (let i = 0; i < array.length; i += 1) {
          const obj = array[i];
          modelClass.create(obj);
        }
        break;
      }
      default:
        break;
    }
    return null;
  }
}

User.modelName = 'User';

User.fields = {
  id: attr(),
  name: attr(),
  account_type: attr(),
  max_projects: attr(),
  email: attr(),
  image: attr(),
};

export default User;
