import { createSelector } from 'redux-orm';

import orm from '../reducers/orm';

const getRef = obj => (obj != null ? obj.ref : null);

/*
returns true in case of projects in orm, else returns false.
WARNING: if for any case the user has 0 projects, the app will be
able to enter a loop of fetching projects waiting to get at least one.
*/
export const fetchedProjects = createSelector(
  orm,
  (_, state) => state.orm,
  (session) => {
    return session.Project.count() > 0;
  },
);

export const allProjects = createSelector(
  orm,
  (_, state) => state.orm,
  (session) => {
    const projects = session.Project
      .filter(o => o.is_favorite)
      .orderBy(o => o.name.toUpperCase())
      .toRefArray()
      .map((project) => {
        return Object.assign({}, project, {
          folder: getRef(session.Folder.get(o => o.id === project.folder_id)),
          boards: session.Board.all()
            .filter(o => o.project_id === project.id).toRefArray(),
        });
      });
    return projects;
  },
);

export const oneProject = createSelector(
  orm,
  (_, state) => state.orm,
  props => props.match.params.id, // Saca el id de la ruta
  (session, projectId) => {
    let project = session.Project
      .get(o => o.id === projectId);
    project = getRef(project);
    if (!project) return null;
    return Object.assign({}, project, {
      folder: getRef(session.Folder
        .get(o => o.id === project.folder_id)),
    });
  },
);

/*
returns the project which is going to be Edited in the
home route. (Edit Modal Form).
*/
export const oneProjectFormModal = createSelector(
  orm,
  (_, state) => state.orm,
  (_, state) => state.modal.projects.selectedProject,
  (session, projectId) => {
    return session.Project.get(o => o.id === projectId).ref;
  },
);

export const oneProjectUser = createSelector(
  orm,
  (_, state) => state.orm,
  (_, state) => state.user.selectedProjectId,
  (session, projectId) => {
    return session.Project.get(o => o.id === projectId).ref;
  },
);

export const oneProjectUserModal = createSelector(
  orm,
  (_, state) => state.orm,
  (_, state) => state.modal.members.selectedProject,
  (session, projectId) => {
    return session.Project.get(o => o.id === projectId).ref;
  },
);

/*
returns the role the current_user has in the project.
*/
export const getProjectRole = createSelector(
  orm,
  (_, state) => state.orm,
  props => props.match.params.id, // Saca el id de la ruta
  (session, projectId) => {
    let project = session.Project
      .get(o => o.id === projectId);
    project = getRef(project);
    if (!project) return null;
    return project.role;
  },
);

export const projectsByFolder = createSelector(
  orm,
  (_, state) => state.orm,
  props => props.folderId,
  (session, folderId) => {
    return session.Project
      .filter(o => o.folder_id === folderId)
      .orderBy(o => o.name.toUpperCase())
      .toRefArray();
  },
);

export const boardCountByProject = createSelector(
  orm,
  (_, state) => state.orm,
  props => props.project.id,
  (session, projectId) => {
    const count = session.Board
      .filter(o => o.project_id === projectId)
      .count();
    return count > 0 ? count : 1;
  },
);
