import {
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR,
  CLEAR_NOTIFICATION,
} from '../constants/notification';

export default (state = {
  success: '',
  error: '',
}, action) => {
  const { type, payload } = action;

  if (type.includes('_FAILURE')) {
    let { errors } = payload.body;
    if (!errors) {
      errors = ['Something unexpected happened.'];
    }
    const message = errors.length ? errors[0] : '';
    return {
      ...state,
      error: message,
    };
  }

  if (type.includes('_TIMEOUT')) {
    const message = 'Request timeout, something may be wrong with the network connection.';
    return {
      ...state,
      error: message,
    };
  }

  switch (type) {
    case NOTIFICATION_SUCCESS: {
      return {
        ...state,
        success: payload.body.message,
      };
    }
    case NOTIFICATION_ERROR: {
      return {
        ...state,
        error: payload.body.message,
      };
    }
    case CLEAR_NOTIFICATION: {
      return {
        ...state,
        success: '',
        error: '',
      };
    }
    default: {
      return state;
    }
  }
};
