import {
  TOGGLE_CREATE_MODAL_PROJECT,
  TOGGLE_EDIT_MODAL_PROJECT,
  TOGGLE_DELETE_MODAL_PROJECT,
} from '../../constants/modals/projects';

export default (state = {
  selectedFolder: '',
  selectedProject: '',
  projectCreating: false,
  projectEditing: false,
  projectDeleting: false,
}, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_CREATE_MODAL_PROJECT: {
      const projectCreating = !state.projectCreating;
      const selectedFolder = payload.body.folderId ? payload.body.folderId : '';
      const selectedProject = payload.body.id ? payload.body.id : '';
      return {
        ...state,
        projectCreating,
        selectedProject,
        selectedFolder,
      };
    }
    case TOGGLE_EDIT_MODAL_PROJECT: {
      const projectEditing = !state.projectEditing;
      const selectedFolder = payload.body.folderId ? payload.body.folderId : '';
      const selectedProject = payload.body.id ? payload.body.id : '';

      return {
        ...state,
        projectEditing,
        selectedProject,
        selectedFolder,
      };
    }
    case TOGGLE_DELETE_MODAL_PROJECT: {
      const projectDeleting = !state.projectDeleting;
      const selectedFolder = payload.body.folderId ? payload.body.folderId : '';
      const selectedProject = payload.body.id ? payload.body.id : '';

      return {
        ...state,
        projectDeleting,
        selectedProject,
        selectedFolder,
      };
    }
    default: {
      return state;
    }
  }
};
