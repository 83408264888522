import { fk, attr, Model } from 'redux-orm';

import {
  FETCH_CREATE_COLUMN,
  FETCH_UPDATE_COLUMN,
  FETCH_DELETE_COLUMN,
  FETCH_GET_ALL_COLUMNS,
  FETCH_UPDATE_COLUMN_ORDER,
  FETCH_ARCHIVE_TASKS_BY_COLUMN,
  FETCH_COMPLETE_TASKS_BY_COLUMN,
} from '../../constants/api/columns';

import { MODEL_UPDATE_COLUMN_ORDER } from '../../constants/order';

class Column extends Model {
  static create(props) {
    if (props.hasOwnProperty(this.idAttribute) && this.idExists(props[this.idAttribute])) {
      const model = this.withId(props.id);
      return model.update(props);
    }
    return super.create(props);
  }

  static reducer(action, modelClass) {
    const { type, payload } = action;
    switch (type) {
      case 'PUSHER_MOVE_COLUMN': {
        modelClass.withId(payload.id).update({ ...payload, position: payload.pos });
        break;
      }
      case 'PUSHER_CREATE_COLUMN': {
        modelClass.create(payload);
        break;
      }
      case 'PUSHER_DELETE_COLUMN': {
        modelClass.withId(payload.id).delete();
        break;
      }
      case 'PUSHER_UPDATE_COLUMN': {
        modelClass.withId(payload.id).update({ ...payload });
        break;
      }
      case `${FETCH_CREATE_COLUMN}_SUCCESS`:
        modelClass.create(payload.body);
        break;
      case `${FETCH_UPDATE_COLUMN}_SUCCESS`:
        modelClass.create(payload.body);
        break;
      case `${FETCH_DELETE_COLUMN}_SUCCESS`:
        modelClass.withId(payload.body.id).delete();
        break;
      case `${FETCH_GET_ALL_COLUMNS}_SUCCESS`: {
        const array = payload.body.results || payload.body;
        for (let i = 0; i < array.length; i += 1) {
          const obj = array[i];
          modelClass.create(obj);
        }
        break;
      }
      case `${FETCH_ARCHIVE_TASKS_BY_COLUMN}_SUCCESS`: {
        modelClass.withId(payload.body.id).tasks.toModelArray().forEach((task) => {
          task.update({ is_archived: true });
        });
        break;
      }
      case `${FETCH_COMPLETE_TASKS_BY_COLUMN}_SUCCESS`: {
        modelClass.withId(payload.body.id).tasks.toModelArray().forEach((task) => {
          task.update({ completed: true });
        });
        break;
      }
      case `${FETCH_UPDATE_COLUMN_ORDER}_SUCCESS`:
        for (let i = 0; i < payload.body.columns.length; i += 1) {
          const obj = payload.body.columns[i];
          modelClass.withId(obj.id).update({ position: obj.position });
        }
        break;
      case MODEL_UPDATE_COLUMN_ORDER: {
        modelClass.withId(payload.body.id).update({ position: payload.body.position });
        break;
      }
      default:
        break;
    }
    return null;
  }
}

Column.modelName = 'Column';

Column.fields = {
  id: attr(),
  name: attr(),
  position: attr(),
  board_id: fk('Board', 'boards'),
};

export default Column;
