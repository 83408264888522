// project actions
export const SELECT_PROJECT = 'SELECT_PROJECT';
export const SELECT_BOARD = 'SELECT_BOARD';
export const SELECT_BOARD_GANTT = 'SELECT_BOARD_GANTT';
export const SELECT_TASK = 'SELECT_TASK';
export const SELECT_MILESTONE = 'SELECT_MIELSTONE'
export const HIDE_ALL_BOARD = 'HIDE_ALL_BOARD';
export const TOGGLE_BOARD_SEARCH = 'TOGGLE_BOARD_SEARCH';
export const USER_SEARCH = 'USER_SEARCH';

// reset state
export const UNSELECT_ALL = 'UNSELECT_ALL';

// user Settings
export const TOGGLE_DROPDOWN_SETTING = 'TOGGLE_DROPDOWN_SETTING';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
