import {
  TOGGLE_CREATE_MODAL_LABEL,
  TOGGLE_EDIT_MODAL_LABEL,
  TOGGLE_GET_MODAL_BOARD_LABELS,
} from '../../constants/modals/labels';

export default (state = {
  labelEditing: false,
  labelCreating: false,
  selectedLabelId: '',
  boardLabelsShowing: false,
  selectedBoardId: '',
}, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_CREATE_MODAL_LABEL: {
      return {
        ...state,
        labelEditing: false,
        labelCreating: !state.labelCreating,
      };
    }
    case TOGGLE_EDIT_MODAL_LABEL: {
      let labelEditing = null;
      let selectedLabelId = '';

      if (state.selectedLabelId !== payload.body.id && state.labelEditing) {
        labelEditing = true;
        selectedLabelId = payload.body.id;
      } else {
        labelEditing = !state.labelEditing;
        selectedLabelId = payload.body.id ? payload.body.id : '';
      }
      return {
        ...state,
        selectedLabelId,
        labelEditing,
      };
    }
    case TOGGLE_GET_MODAL_BOARD_LABELS: {
      const boardLabelsShowing = !state.boardLabelsShowing;
      const labelEditing = state.labelEditing ? !state.labelEditing : state.labelEditing;
      const selectedBoardId = payload.body.id ? payload.body.id : '';
      return {
        ...state,
        selectedBoardId,
        labelEditing,
        boardLabelsShowing,
      };
    }
    default: {
      return state;
    }
  }
};
