import {
  FETCH_CREATE_INVITATION,
  FETCH_UPDATE_INVITATION,
  FETCH_DELETE_INVITATION,
  FETCH_GET_ALL_INVITATIONS,
  FETCH_GET_ALL_INVITATIONS_SENT,
  FETCH_CREATE_ACCEPT_INVITATION,
  FETCH_CREATE_REJECT_INVITATION,
} from '../../constants/api/invitations';

export const fetchGetInvitations = () => ({
  type: FETCH_GET_ALL_INVITATIONS,
  fetch: true,
  url: '/project_invitations/',
  params: {
    method: 'GET',
  },
});

export const fetchGetInvitationsSent = projectId => ({
  type: FETCH_GET_ALL_INVITATIONS_SENT,
  fetch: true,
  url: `/projects/${projectId}/invitations`,
  params: {
    method: 'GET',
  },
});

export const fetchCreateInvitation = (id, body) => ({
  type: FETCH_CREATE_INVITATION,
  fetch: true,
  url: `/projects/${id}/invitations`,
  params: {
    method: 'POST',
    body,
  },
});

export const fetchUpdateInvitation = (id, body) => ({
  type: FETCH_UPDATE_INVITATION,
  fetch: true,
  url: `/project_invitations/${id}/`,
  params: {
    method: 'PUT',
    body,
  },
});

export const fetchDeleteInvitation = id => ({
  type: FETCH_DELETE_INVITATION,
  fetch: true,
  url: `/project_invitations/${id}/`,
  params: {
    method: 'DELETE',
  },
});

export const fetchAcceptInvitation = (id, body) => ({
  type: FETCH_CREATE_ACCEPT_INVITATION,
  fetch: true,
  url: `/project_invitations/${id}/accept_invitation`,
  params: {
    method: 'POST',
    body,
  },
});

export const fetchRejectInvitation = id => ({
  type: FETCH_CREATE_REJECT_INVITATION,
  fetch: true,
  url: `/project_invitations/${id}/reject_invitation`,
  params: {
    method: 'POST',
  },
});
