import React from 'react';
import PropTypes from 'prop-types';
import MaterialCircularProgress from '@material-ui/core/CircularProgress';

const innerStyle = {
  position: 'absolute',
  left: '50%',
  bottom: '50%',
  color: '#ea5941',
  zIndex: 1,
};

const CircularProgress = ({ style, ...rest }) => {
  return (
    <MaterialCircularProgress
      size={50}
      color="primary"
      style={{ ...innerStyle, ...style }}
      {...rest}
    />
  );
};

CircularProgress.propTypes = {
  style: PropTypes.object,
};
CircularProgress.defaultProps = {
  style: innerStyle,
};

export default CircularProgress;
