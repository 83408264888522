export const FETCH_ALL_USERS = 'FETCH_ALL_USERS';
export const FETCH_MAX_PROJECTS = 'FETCH_MAX_PROJECTS';
export const FETCH_ADD_ADMIN = 'FETCH_ADD_ADMIN';
export const FETCH_DELETE_ADMIN = 'FETCH_DELETE_ADMIN';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_GIT_NAME = 'FETCH_GIT_NAME';
export const FETCH_LAB_NAME = 'FETCH_LAB_NAME';
export const FETCH_SLACK_ID = 'FETCH_SLACK_ID';
export const FETCH_SEMESTER = 'FETCH_SEMESTER';
export const FETCH_SECTION = 'FETCH_SECTION';
export const FETCH_GROUP = 'FETCH_GROUP';
export const FETCH_YEAR = 'FETCH_YEAR';
