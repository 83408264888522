import {
  fk, attr, Model,
} from 'redux-orm';

import {
  FETCH_CREATE_PROJECT,
  FETCH_UPDATE_PROJECT,
  FETCH_DELETE_PROJECT,
  FETCH_GET_PROJECT,
  FETCH_GET_ALL_PROJECTS,
  FETCH_UPDATE_PROJECT_FAVORITE,
  FETCH_GITHUB_CONFIRMATION,
  FETCH_GITHUB_REMOVE,
} from '../../constants/api/projects';

import {
  FETCH_CREATE_ACCEPT_INVITATION,
} from '../../constants/api/invitations';

import {
  FETCH_DELETE_MEMBER,
  FETCH_QUIT_PROJECT,
} from '../../constants/api/members';

class Project extends Model {
  static create(props) {
    if (props.hasOwnProperty(this.idAttribute) && this.idExists(props[this.idAttribute])) {
      const model = this.withId(props.id);
      return model.update(props);
    }
    return super.create(props);
  }

  static reducer(action, modelClass) {
    const { type, payload } = action;
    switch (type) {
      case `${FETCH_CREATE_PROJECT}_SUCCESS`:
        modelClass.create(payload.body);
        break;
      case `${FETCH_CREATE_ACCEPT_INVITATION}_SUCCESS`:
        if (payload.body.accepted) {
          modelClass.create({ ...payload.body, id: payload.body.project_id });
        }
        break;
      case `${FETCH_UPDATE_PROJECT}_SUCCESS`:
        modelClass.withId(payload.body.id).update(payload.body);
        break;
      case `${FETCH_UPDATE_PROJECT_FAVORITE}_SUCCESS`:
        modelClass.withId(payload.body.id).update(payload.body);
        break;
      case `${FETCH_DELETE_PROJECT}_SUCCESS`:
        modelClass.withId(payload.body.id).delete();
        break;
      case `${FETCH_GET_PROJECT}_SUCCESS`:
        modelClass.create(payload.body);
        break;
      case `${FETCH_GET_ALL_PROJECTS}_SUCCESS`: {
        const array = payload.body.results || payload.body;
        for (let i = 0; i < array.length; i += 1) {
          const obj = array[i];
          modelClass.create(obj);
        }
        break;
      }
      case `${FETCH_GITHUB_CONFIRMATION}_SUCCESS`: {
        const project = modelClass.withId(payload.body.project_id);
        const newProject = Object.assign({}, project, {
          has_github: true,
        });
        modelClass.withId(payload.body.project_id).update(newProject);
        break;
      }
      case `${FETCH_GITHUB_REMOVE}_SUCCESS`: {
        const project = modelClass.withId(payload.body.project_id);
        const newProject = Object.assign({}, project, {
          has_github: false,
        });
        modelClass.withId(payload.body.project_id).update(newProject);
        break;
      }
      case `${FETCH_QUIT_PROJECT}_SUCCESS`: {
        modelClass.withId(payload.body.project_id).delete();
        break;
      }
      case `${FETCH_DELETE_MEMBER}_SUCCESS`: {
        const project = modelClass.withId(payload.body.project_id);
        const newProject = Object.assign({}, project, {
          members: project.members.filter(memberId => memberId !== payload.body.member_id),
        });
        modelClass.withId(payload.body.project_id).update(newProject);
      }
        break;
      default:
        break;
    }
    return null;
  }
}

Project.modelName = 'Project';

Project.fields = {
  id: attr(),
  name: attr(),
  description: attr(),
  role: attr(),
  has_github: attr(),
  is_private: attr(),
  is_favorite: attr(),
  members: attr(), // Array
  folder_id: fk('Folder', 'projects'),
};

export default Project;
