import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import DropdownComponent from './DropdownComponent';
import AppLogo from './AppLogo';
import LanguageSelector from './LanguageSelector';
import Notifications from './Notifications';
import { oneProject } from '../../selectors/projects';
import language from '../../constants/language';

const styles = theme => ({
  appbar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  root: {
    height: 45,
    backgroundColor: '#2a3d41',
    padding: '0px 20px',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    color: 'white',
    fontSize: 19,
    fontWeight: 'bold',
  },
  titleText: {
    paddingTop: 7,
  },
  link: {
    fontSize: 16,
    margin: '0px 5px',
    color: 'white',
    textDecoration: 'none',
  },
  titleIcon: {
    color: '#ea5941',
    width: 33,
    height: 'auto',
    marginRight: 8,
  },
  grow: {
    flexGrow: 1,
  },
});

const Logo = ({ project, classes }) => {
  return (
    <Typography className={classes.title}>
      <AppLogo alt="logo" style={{ paddingRight: '5px' }} />
      <span className={classes.titleText}>

        ZmartBoard
      </span>
      {
        project
        && (
          <Fragment>
            <FolderIcon className={classes.titleIcon} />
            <span className={classes.titleText}>
              {project.folder.name}
            </span>
            <FolderIcon className={classes.titleIcon} />
            <span className={classes.titleText}>
              {project.name}
            </span>
          </Fragment>
        )
      }
    </Typography>
  );
};

Logo.propTypes = {
  classes: PropTypes.any.isRequired,
  project: PropTypes.object,
};

Logo.defaultProps = {
  project: null,
};

const Header = ({
  isAuthenticated,
  project,
  classes,
  selectedLanguage,
}) => {
  return (
    <AppBar className={classes.appbar}>
      <Fragment>
        <Grid container alignItems="center" className={classes.root}>
          {
          !isAuthenticated && (
            <Fragment>
              <Link to="/" className={classes.link}>
                <Logo classes={classes} />
              </Link>
              <div className={classes.grow} />
              <div style={{ width: 90 }}>
                <LanguageSelector />
              </div>
              <Link to="/login" className={classes.link}>
                <Typography className={classes.link}>
                  {language[selectedLanguage].login.login}
                </Typography>
              </Link>
              <Link to="register" className={classes.link}>
                <Typography className={classes.link}>
                  {language[selectedLanguage].login.register}
                </Typography>
              </Link>
            </Fragment>
          )
        }
          {
          isAuthenticated && (
            <Fragment>
              <Link to="/home" className={classes.link}>
                <Logo project={project} classes={classes} />
              </Link>
              <div className={classes.grow} />
              <div style={{ width: 90 }}>
                <LanguageSelector />
              </div>
              <div style={{ width: 10 }} />
              <Notifications />
              <DropdownComponent />
            </Fragment>
          )
        }
        </Grid>
      </Fragment>
    </AppBar>
  );
};

Header.propTypes = {
  isAuthenticated: PropTypes.bool,
  project: PropTypes.object,
  classes: PropTypes.any.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
};

Header.defaultProps = {
  isAuthenticated: false,
  project: null,
};

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    project: oneProject(ownProps, state),
    selectedLanguage: state.user.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header)),
);
