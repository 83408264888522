import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import BlankProfile from '../../../assets/blankprofile.png';

const styles = () => ({
  customIcon: {
    height: '24px',
    width: '24px',
    display: 'block',
    'border-radius': '50%',
  },
});

class CustomSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick = (event) => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
    if (this.props.onClick) this.props.onClick();
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl, open } = this.state;
    const { userImage, classes, ...props } = this.props;
    return (
      <Fragment>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          className={classes.button}
        >
          {(!userImage || userImage === 'null')
          && (
            <img alt="" className={classes.customIcon} src={BlankProfile} />
          )}
          {(userImage && userImage !== 'null')
          && (
            <img alt="" className={classes.customIcon} src={userImage} />
          )}
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          {...props}
        >
          {this.props.children}
        </Menu>
      </Fragment>
    );
  }
}

CustomSetting.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  open: PropTypes.bool.isRequired,
  userImage: PropTypes.string.isRequired,
};

CustomSetting.defaultProps = {
  onClick: undefined,
};

function mapStateToProps(state) {
  return {
    userImage: state.auth.image,
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomSetting));
