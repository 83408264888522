import {
  TOGGLE_ACCEPT_MODAL_INVITATION,
  TOGGLE_SEND_MODAL_INVITATION,
  TOGGLE_EDIT_MODAL_INVITATION,
} from '../../constants/modals/invitations';

export default (state = {
  selectedInvitation: '',
  selectedProject: '',
  invitationAccepting: false,
  invitationSending: false,
  invitationEditing: false,
}, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_ACCEPT_MODAL_INVITATION: {
      // Modal route: Home
      const invitationAccepting = !state.invitationAccepting;
      const selectedInvitation = payload.body.id ? payload.body.id : '';
      return {
        ...state,
        invitationAccepting,
        selectedInvitation,
      };
    }
    case TOGGLE_SEND_MODAL_INVITATION: {
      // Modal route: Project
      const invitationSending = !state.invitationSending;
      const selectedProject = payload.body.id ? payload.body.id : '';
      return {
        ...state,
        invitationSending,
        selectedProject,
      };
    }
    case TOGGLE_EDIT_MODAL_INVITATION: {
      const invitationEditing = !state.invitationEditing;
      const selectedProject = payload.body.id ? payload.body.id : '';
      return {
        ...state,
        invitationEditing,
        selectedProject,
      };
    }
    default: {
      return state;
    }
  }
};
