import {
  TOGGLE_CREATE_MODAL_FOLDER,
  TOGGLE_EDIT_MODAL_FOLDER,
  TOGGLE_DELETE_MODAL_FOLDER,
  TOGGLE_ARCHIVED_MODAL_FOLDERS,
  SET_FETCHING_ARCHIVED,
} from '../../constants/modals/folders';

export default (state = {
  selectedFolder: '',
  folderCreating: false,
  folderEditing: false,
  folderDeleting: false,
  folderArchived: false,
  isFetching: false,
}, action) => {
  const { type, payload } = action;
  switch (type) {
    case TOGGLE_CREATE_MODAL_FOLDER: {
      const folderCreating = !state.folderCreating;
      const selectedFolder = '';
      return {
        ...state,
        folderCreating,
        selectedFolder,
      };
    }
    case TOGGLE_EDIT_MODAL_FOLDER: {
      const folderEditing = !state.folderEditing;
      const selectedFolder = payload.body.id ? payload.body.id : '';
      return {
        ...state,
        folderEditing,
        selectedFolder,
      };
    }
    case TOGGLE_DELETE_MODAL_FOLDER: {
      const folderDeleting = !state.folderDeleting;
      const selectedFolder = payload.body.id ? payload.body.id : '';
      return {
        ...state,
        folderDeleting,
        selectedFolder,
      };
    }
    case TOGGLE_ARCHIVED_MODAL_FOLDERS: {
      const folderArchived = !state.folderArchived;
      const selectedFolder = '';
      return {
        ...state,
        folderArchived,
        selectedFolder,
      };
    }
    case SET_FETCHING_ARCHIVED: {
      const setBool = payload.body.bool ? payload.body.bool : false;
      return {
        ...state,
        isFetching: setBool,
      };
    }
    default: {
      return state;
    }
  }
};
