import { fk, attr, Model } from 'redux-orm';

import {
  FETCH_CREATE_TASK,
  FETCH_UPDATE_TASK,
  FETCH_ARCHIVE_TASK,
  FETCH_DELETE_TASK,
  FETCH_GET_ALL_TASKS,
  FETCH_GET_ALL_ARCHIVED_TASKS,
  FETCH_UPDATE_TASK_ORDER,
  FETCH_UPDATE_TASK_ORDER_AND_COLUMN,
  FETCH_POST_TASK_COMMIT,
  FETCH_DELETE_TASK_COMMIT,
} from '../../constants/api/tasks';

import {
  FETCH_ADD_HOURS,
} from '../../constants/api/hours';

import { FETCH_TASK_ASSIGN, FETCH_TASK_UNASSIGN } from '../../constants/api/members';
import { FETCH_TASK_ADD_LABEL, FETCH_TASK_REMOVE_LABEL } from '../../constants/api/labels';
import { MODEL_UPDATE_TASK_ORDER, MODEL_UPDATE_TASK_ORDER_WITH_COLUMN } from '../../constants/order';

class Task extends Model {
  static create(props) {
    if (props.hasOwnProperty(this.idAttribute) && this.idExists(props[this.idAttribute])) {
      const model = this.withId(props.id);
      return model.update(props);
    }
    return super.create(props);
  }

  static reducer(action, modelClass) {
    const { type, payload } = action;
    switch (type) {
      case 'PUSHER_MOVE_TASK': {
        modelClass.withId(payload.id).update({ ...payload, position: payload.pos });
        break;
      }
      case 'PUSHER_CREATE_TASK': {
        modelClass.create(payload);
        break;
      }
      case 'PUSHER_DELETE_TASK': {
        modelClass.withId(payload.id).delete();
        break;
      }
      case 'PUSHER_UPDATE_TASK': {
        const task = modelClass.withId(payload.id);
        const newTask = Object.assign({}, payload, {
          labels: task.labels,
          assigned: task.assigned,
        });
        modelClass.withId(payload.id).update(newTask);
        break;
      }
      case 'PUSHER_ADD_LABEL': {
        const task = modelClass.withId(payload.task_id);
        const newTask = Object.assign({}, task, {
          labels: task.labels.slice(0),
        });
        newTask.labels.push(payload.id);
        modelClass.withId(payload.task_id).update(newTask);
        break;
      }
      case 'PUSHER_REMOVE_LABEL': {
        const task = modelClass.withId(payload.task_id);
        const newTask = Object.assign({}, task, {
          labels: task.labels.filter(labelId => labelId !== payload.id),
        });
        modelClass.withId(payload.task_id).update(newTask);
        break;
      }
      case `${FETCH_CREATE_TASK}_SUCCESS`: {
        modelClass.create(payload.body);
        break;
      }
      case `${FETCH_UPDATE_TASK}_SUCCESS`: {
        const task = modelClass.withId(payload.body.id);
        const newTask = Object.assign({}, payload.body, {
          labels: task.labels,
          assigned: task.assigned,
        });
        modelClass.withId(payload.body.id).update(newTask);
        break;
      }
      case `${FETCH_ARCHIVE_TASK}_SUCCESS`: {
        modelClass.withId(payload.body.id)
          .update({ is_archived: payload.body.is_archived });
        break;
      }
      case `${FETCH_DELETE_TASK}_SUCCESS`: {
        modelClass.withId(payload.body.id).delete();
        break;
      }
      case `${FETCH_GET_ALL_TASKS}_SUCCESS`: {
        const array = payload.body.results || payload.body;
        for (let i = 0; i < array.length; i += 1) {
          const obj = array[i];
          modelClass.create(obj);
        }
        break;
      }
      case `${FETCH_GET_ALL_ARCHIVED_TASKS}_SUCCESS`: {
        const array = payload.body.results || payload.body;
        for (let i = 0; i < array.length; i += 1) {
          const obj = array[i];
          modelClass.create(obj);
        }
        break;
      }
      case `${FETCH_UPDATE_TASK_ORDER}_SUCCESS`: {
        for (let i = 0; i < payload.body.tasks.length; i += 1) {
          const obj = payload.body.tasks[i];
          modelClass.withId(obj.id).update({ position: obj.position });
        }
        break;
      }
      case `${FETCH_UPDATE_TASK_ORDER_AND_COLUMN}_SUCCESS`: {
        for (let i = 0; i < payload.body.tasks.length; i += 1) {
          const obj = payload.body.tasks[i];
          modelClass.withId(obj.id)
            .update({ position: obj.position, column_id: payload.body.column_id });
        }
        break;
      }
      case MODEL_UPDATE_TASK_ORDER: {
        modelClass.withId(payload.body.id).update({ position: payload.body.position });
        break;
      }
      case MODEL_UPDATE_TASK_ORDER_WITH_COLUMN: {
        modelClass.withId(payload.body.id).update({
          position: payload.body.position,
          column_id: payload.body.column_id,
        });
        break;
      }
      case `${FETCH_TASK_ASSIGN}_SUCCESS`: {
        const task = modelClass.withId(payload.body.task_id);
        const newTask = Object.assign({}, task, {
          assigned: task.assigned.slice(0),
        });
        newTask.assigned.push(payload.body.user_id);
        modelClass.withId(payload.body.task_id).update(newTask);
        break;
      }
      case `${FETCH_TASK_UNASSIGN}_SUCCESS`: {
        const task = modelClass.withId(payload.body.task_id);
        const newTask = Object.assign({}, task, {
          assigned: task.assigned.filter(userId => userId !== payload.body.user_id),
        });
        modelClass.withId(payload.body.task_id).update(newTask);
        break;
      }
      case `${FETCH_TASK_ADD_LABEL}_SUCCESS`: {
        const task = modelClass.withId(payload.body.task_id);
        const newTask = Object.assign({}, task, {
          labels: task.labels.slice(0),
        });
        newTask.labels.push(payload.body.label_id);
        modelClass.withId(payload.body.task_id).update(newTask);
        break;
      }
      case `${FETCH_TASK_REMOVE_LABEL}_SUCCESS`: {
        const task = modelClass.withId(payload.body.task_id);
        const newTask = Object.assign({}, task, {
          labels: task.labels.filter(labelId => labelId !== payload.body.label_id),
        });
        modelClass.withId(payload.body.task_id).update(newTask);
        break;
      }
      case `${FETCH_ADD_HOURS}_SUCCESS`: {
        const task = modelClass.withId(payload.body.task_id);
        const newTask = Object.assign({}, task, {
          total_hours: (+payload.body.hours + +task.total_hours),
        });
        modelClass.withId(payload.body.task_id).update(newTask);
        break;
      }
      case `${FETCH_POST_TASK_COMMIT}_SUCCESS`: {
        const task = modelClass.withId(payload.body.task_id);
        const newTask = Object.assign({}, task, {
          commit: { message: payload.body.message, url: payload.body.url, id: payload.body.id },
        });
        modelClass.withId(payload.body.task_id).update(newTask);
        break;
      }
      case `${FETCH_DELETE_TASK_COMMIT}_SUCCESS`: {
        const task = modelClass.withId(payload.body.task_id);
        const newTask = Object.assign({}, task, {
          commit: { message: null, url: null, id: null },
        });
        modelClass.withId(payload.body.task_id).update(newTask);
        break;
      }
      default:
        break;
    }
    return null;
  }
}

Task.modelName = 'Task';

Task.fields = {
  id: attr(),
  title: attr(),
  position: attr(),
  is_archived: attr(),
  labels: attr(), // Array []
  assigned: attr(), // Array []
  column_id: fk('Column', 'tasks'),
  total_hours: attr(),
};

export default Task;
