import { combineEpics } from 'redux-observable';

import pusherEpic from './pusher';
import searchEpic from './search';
import fetchEpic from './fetch';

const rootEpic = combineEpics(
  pusherEpic,
  searchEpic,
  fetchEpic,
);

export default rootEpic;
