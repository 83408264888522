import { SELECT_BOARD } from '../constants/user';

import {
  PUSHER_CONNECT,
  PUSHER_UNSUBSCRIBE_CHANNEL,
} from '../constants/pusher';

const pusherMiddleware = store => next => (action) => {
  const { dispatch } = store;
  if (action.type === SELECT_BOARD) {
    const prevBoardId = store.getState().user.selectedBoardId;
    if (prevBoardId !== '') {
      const payload = {
        body: {
          id: prevBoardId,
        },
      };
      dispatch({ type: PUSHER_UNSUBSCRIBE_CHANNEL, payload });
    }

    if (prevBoardId !== action.payload.body.id) {
      dispatch({
        type: PUSHER_CONNECT,
        id: action.payload.body.id,
      });
    }
  }
  return next(action);
};

export default pusherMiddleware;
