import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaterialFormHelperText from '@material-ui/core/FormHelperText';

/* eslint-disable react/prefer-stateless-function, react/require-default-props */
class FormHelperText extends Component {
  render() {
    const { children } = this.props;
    return (
      <MaterialFormHelperText>
        {children}
      </MaterialFormHelperText>
    );
  }
}

FormHelperText.propTypes = {
  children: PropTypes.node,
};

export default FormHelperText;
