export const TOGGLE_CHATBOT_MODAL = 'TOGGLE_CHATBOT_MODAL';

export const toggleChatbotModal = (isShown, defaultMessage = '', image = '') => ({
    type: TOGGLE_CHATBOT_MODAL,
    payload: {
        isShown,
        defaultMessage,
        image,
    },
});
