import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import BaseButton from '../BaseButton';

const styles = {
  cssRoot: {
    backgroundColor: '#ea5941',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#ca3921',
    },
  },
};

const PrimaryButton = ({ classes, ...rest }) => {
  return (
    <BaseButton
      color="primary"
      className={classes.cssRoot}
      {...rest}
    />
  );
};

PrimaryButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PrimaryButton);
