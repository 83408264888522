import {
  TOGGLE_EDIT_MODAL_MEMBER_ROLE,
} from '../../constants/modals/members';

export default (state = {
  selectedProject: '',
  roleEditing: false,
}, action) => {
  const { type, payload } = action;

  switch (type) {
    // Modal route: Project
    case TOGGLE_EDIT_MODAL_MEMBER_ROLE: {
      const roleEditing = !state.roleEditing;
      const selectedProject = payload.body.id ? payload.body.id : '';
      return {
        ...state,
        roleEditing,
        selectedProject,
      };
    }
    default: {
      return state;
    }
  }
};
