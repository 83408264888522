import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import GA4React from 'ga-4-react';
import TagManager from 'react-gtm-module';
import Content from './routes';
import Header from './components/app/Header';
import CustomizedSnackbars from './components/lib/Snackbar';
import NetworkWrapper from './components/app/NetworkWrapper';
import ChatModal from './components/ChatModal/ChatModal';

// Importing @sentry/tracing patches the global hub for tracing to work.

Sentry.init({
  dsn: 'https://608302fc2d7b4248be2e9244875e3f62@o1038934.ingest.sentry.io/6007594',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

function App() {
  const tagManagerArgs = {
    gtmId: 'GTM-5S37FS4',
  };
  TagManager.initialize(tagManagerArgs);

  useEffect(() => {
    const ga4react = new GA4React('G-7E7YFGY0BZ');
    ga4react.initialize().then((ga4) => {
      ga4.pageview(window.location.pathname);
    }, (err) => {
      console.error(err);
    });
  }, []);

  return (
    <NetworkWrapper>
      <div style={{ paddingTop: 45 }}>
        <CustomizedSnackbars />
      </div>
      <Content />
      <ChatModal modalType={'standard'}/>
      <ChatModal modalType={'image code'}/>
      <ChatModal modalType={'image stories'}/>

      <div><Header /></div>
    </NetworkWrapper>
  );
}

export default (App);
