import MailIcon from '@material-ui/icons/Mail';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Grow from '@material-ui/core/Grow';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import InvitationsContainer from '../home/InvitationsContainer';
import { allInvitations } from '../../selectors/invitations';
import { fetchGetInvitations } from '../../actions/api/invitations';

const styles = theme => ({

  colorPrimary: {
    backgroundColor: '#2a3d41',
    color: 'white',
    margin: theme.spacing.unit,
    '&:hover': {
      backgroundColor: '#2a3d41',
    },
  },
  dot: {
    height: 10,
    minWidth: 10,
  },
  container: {
    width: 250,
    flex: 1,
    padding: '10px 10px',
    backgroundColor: 'rgb(249, 249, 249)',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },
});

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invisible: true,
      open: false,
    };
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchGetInvitations().then(() => {
      if (this.props.invitations.length) {
        this.setState({ invisible: false });
      } else {
        this.setState({ invisible: true });
      }
    });
  }

  onClick = () => {
    this.setState(state => ({
      open: !state.open,
    }));
  }

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    if (this.props.invitations.length) {
      this.setState({ invisible: false });
    } else {
      this.setState({ invisible: true });
    }
    this.setState({ open: false });
  };

  render() {
    const { classes, invitations } = this.props;
    const { invisible } = this.state;
    const { open } = this.state;
    return (
      <div>

        <Button
          className={classes.colorPrimary}
          onClick={this.onClick}
          buttonRef={(node) => {
            this.anchorEl = node;
          }}
        >
          <Badge classes={{ dot: classes.dot }} color="primary" variant="dot" invisible={invisible}>
            <MailIcon />
          </Badge>
        </Button>

        <Popper open={open} style={{ zIndex: 1 }} anchorEl={this.anchorEl} transition placement="left-start" disablePortal>
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
            >
              <Paper square>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <Grid item container className={classes.container}>
                    <InvitationsContainer invitations={invitations} />
                  </Grid>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>

    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    invitations: allInvitations(ownProps, state),
  };
};

Notifications.propTypes = {
  classes: PropTypes.object.isRequired,
  invitations: PropTypes.array.isRequired,
  fetchGetInvitations: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchGetInvitations,
    },
    dispatch,
  );
};

export default withRouter(
  connect(
    mapStateToProps, mapDispatchToProps,
  )(withStyles(styles)(Notifications)),
);