import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Form extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(event) {
    if (event) event.preventDefault();
    if (this.props.onSubmit != null) {
      this.props.onSubmit(event);
    }
  }

  render() {
    const { children, ...props } = this.props;
    return (
      <form
        onSubmit={this.onSubmit}
        {...props}
      >
        {children}
      </form>
    );
  }
}

Form.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  onSubmit: () => {},
};

export default Form;
