import { logout } from '../actions/api/auth';

const authMiddleware = store => next => (action) => {
  const { dispatch } = store;
  const { payload, type } = action;
  if (payload) {
    if (payload.status === 401 && type !== 'FETCH_AUTH_LOGIN_FAILURE') {
      dispatch(logout());
    }
  }
  return next(action);
};

export default authMiddleware;
