// in ActionProvider.jsx
import React from 'react';
import { connect } from 'react-redux';
import { toggleChatbotModal } from "../actions/modals/chatbot";

const ActionProvider = ({ createChatBotMessage, setState, children, toggleChatbotModal }) => {
  const openChatModal = () => {
    toggleChatbotModal(true);
  };


  const handleHello = () => {
    const botMessage = createChatBotMessage('Hello. Nice to meet you.');

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleEntregas = async ({selectedLanguage}) => {
    const botMessage = createChatBotMessage("Aqui esta la informacion que buscas",
    {
      widget: 'entregas',
    });

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleEquipoDocente = async () => {
    const botMessage = createChatBotMessage("Aqui esta la informacion que buscas",
    {
      widget: 'equipoDocente',
    });

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleMenu = async () => {
    const botMessage = createChatBotMessage("¿En qué puedo ayudarte?",
    {
      widget: 'menu',
    });

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handlePlantillas = () => {
    const botMessage = createChatBotMessage(
      'Aqui esta el link que buscas',
      {
        widget: 'plantillas',
      }
    )

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handleRecomendacion = () => {
    openChatModal();
    const botMessage = createChatBotMessage(
      'Cargando motor de recomendaciones...',
      {
        widget: 'recomendacion',
      }
    )

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handleRecomendacionButtons = async (message = "¿En qué puedo ayudarte?") => {
    const botMessage = createChatBotMessage(message,
    {
      widget: 'recomendacionButtons',
    });

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleRecomendacionOptions = async () => {
    const botMessage = createChatBotMessage("¿Qué tipo de recomendación visual quieres?",
    {
      widget: 'recomendacionOptions',
    });

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleImagenCodigo = async () => {
    toggleChatbotModal(true, '', 'code');
    const botMessage = createChatBotMessage(
      'Cargando motor de recomendaciones...',
      {
        widget: 'recomendacion',
      }
    )

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleImagenRequisitos = async () => {
    toggleChatbotModal(true, '', 'stories');
    const botMessage = createChatBotMessage(
      'Cargando motor de recomendaciones...',
      {
        widget: 'recomendacion',
      }
    )

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  // Put the handleHello function in the actions object to pass to the MessageParser
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleHello,
            handleEntregas,
            handleEquipoDocente,
            handlePlantillas,
            handleRecomendacion,
            handleMenu,
            handleRecomendacionButtons,
            handleRecomendacionOptions,
            handleImagenCodigo,
            handleImagenRequisitos
          },
        });
      })}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    auth: state.auth,
    selectedLanguage: state.user.selectedLanguage,
  };
}


export default connect(mapStateToProps, { toggleChatbotModal })(ActionProvider);