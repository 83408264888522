
export default {
  en: {
    bot: {
      headerText: 'ZBot',
      placeholderText: 'Haz una consulta',
      title: "Conversation with Zbot",
      firstMessage: "Hello, I am Zbot, the virtual assistant of ZmartBoard. How can I assist you? You can always write 'menu' to see the options",
      evaluations: "Deliveries",
      teachingTeam: "Teaching Team",
      recomendation: "I want a recommendation",
      templates: "Templates",
      chat: {
        firstMessage: "Enter your query, for best results try to be as specific as possible including technologies, languages, context, etc.",
        title: "Zbot Recommendations",
        errorMessage: 'An error has occurred in the AI-based processing engine that provides the cognitive service for the development of this Tip, please try again.',
        placeholder: "Enter your query",
        send: "Send",
        isGenerating: "The responses are being generated, you can view them by moving between the different tabs. This may take a few seconds due to the processing performed by the AI-based engine developed to provide the cognitive services that generate the different tips.",
        feedbackThanks: "Thanks for your feedback!",
        feedbackQuestion: "Did you find the answer useful?",
        feedbackYes: "Yes",
        feedbackNo: "No",
      },
    },
    analysis: {
      time: 'Time',
      stress: 'Stress',
      blocking: 'Blocking',
      feel: 'Feeling',
      time_text: 'Indicates if the member had time problems during the week and how serious they were. The closer to 1, the more severe.',
      stress_text: 'Indicates if the member had stress problems during the week and how serious they were. The closer to 1, the more severe.',
      blocking_text: 'Indicates if the member had blocking problems (he faced a problem that prevented him from moving forward) during the week and how serious they were. The closer to 1, the more severe.',
      feel_text: 'Indicates whether what the member reported has a positive or negative feeling. For example: "I had time to rest" is a positive feeling, while "I did not have time because of exams" is a negative feeling. The closer to 0, the more serious.',
      member: 'Member',
      title: 'Analysis Information',
    },
    metrics: {
      rmse_text: 'It is the distance between the score assigned to the recommendation evaluated by the user and the recommendation made by the recommender system. A lower value is better.',
      rmsd_text: 'In this case, we sought to determine how far the recommendations of each engine were from the recommendations evaluated with 1 star by users. A higher value is better.',
      coverage_text: 'It is the percentage of the available lessons that are actually recommended to a user.',
      prediction_text: 'Rating prediction metrics seek to measure how close the ratings estimated by an engine are to the true ratings of users. In this category are RMSE, NRMSE, RMSD and NRMSD.',
      no_prediction: 'Other metrics',
      prediction: 'Prediction metrics',
      coverage: 'Coverage',
      title: 'Metrics Information',
    },
    settings: {
      editProfile: 'Edit profile',
      editSlackBot: 'Edit SlackBot parameters',
      uploadPicture: 'Upload picture',
      submit: 'Submit',
      name: 'Name',
      github: 'GitHub',
      labname: 'Gitlab',
      year: 'Year',
      semester: 'Semester',
      section: 'Section',
      group: 'Group',
      newPass: 'New password',
      confirmPass: 'Confirm password',
      currentPass: 'Current password',
      notifySuccess: 'You updated your account successfully',
      notifyError: 'Password confirmation does not match',
      editDates: 'Edit dates',
      editTeams: 'Edit teams',
      editQuestions: 'Edit Questions',
    },
    dashboard: {
      editChatBot: 'Edit ChatBot parameters',
      chatbotEdit: {
        name: 'Name',
        work: 'Work Position',
        section: 'Section',
        group: 'Group',
        email: 'Email',
        semester: 'Semester',
        year: 'Year',
        actions: 'Actions',
        create: 'Create',
        search: 'Search',
        selectYear: 'Select Year',
        selectSem: 'Select Semester',
        cicle: 'Cicle',
        date: 'Date',
        updateCicles: 'Update Cicles',
        edit: 'Edit',
        delete: 'Delete',
        update: 'Update',
      },
      metric: {
        evaluation: 'Evaluation Motor',
        metric: 'Metric',
        cosine: 'Cosine Engine',
        lev: 'Levenshtein Engine',
        pond: 'Balanced Engine',
        coverage: 'Coverage',
        system: 'Recommender sytem',
      },
      dashboardTitle: 'Administrator dashboard',
      editTerms: 'Edit Terms and Conditions',
      nameTermsConditions: 'Terms and Conditions',
      history: 'History',
      editSuccess: "Updated Succesfully",
      deleteSuccess: "Deleted Succesfully",
      askConfirmation: "Are you sure you want to delete?",
      cannotChange: "Unable to Change",
      uploadTerms: "Upload file",
      termsHistory: {
        createdAt: "Created At",
        language: "Language",
        name: "Name",
        download: "Download",
      },  
      unset: "No file available",
      recommendations: "Recommendations Systems",
      user: "Users",
      terms: "Terms & Conditions",
      users: {
        filterBar: "Filter Bar",
        listLength: "List Length",
        orderBy: "Order By",
        name: "Name",
        lastSignIn: "Last Sign In",
        orderSense: "Order Sense",
        searchButton: "Search",
        photo: "Photo",
        semester: "Semester",
        section: "Section",
        group: "Group",
        year: "Year",
        projects: "Projects",
        actions: "Actions",
        last: "Last",
        next: "Next",
        edit: "Edit",
        delete: "Delete",
        submit: "Submit",
      },
    },
    share: 'Share URL',
    app: {
      noConnection: 'No internet connection available',
      uploadComplete: 'File uploaded correctly',
    },
    date_change: {
      success: 'Dates changed correctly.',
    },
    login: {
      confirmRegistration: 'You have to confirm email to finish registration',
      confirmPassword: 'Password Confirmation',
      welcomeTo: 'Welcome to',
      login: 'Login',
      signin: 'Sign In',
      register: 'Sign Up',
      incompleteCred: 'Incomplete credentials',
      email: 'Email',
      emailNotValid: 'Email is not valid',
      password: 'Password',
      forgotPass: 'Forgot Password',
      googleLogin: 'Login with Google',
      resetPasswordEmailSent: 'An email has been sent to reset password',
      resetPassword: 'Reset Password',
      resend: 'Resend',
      resendConfirmation: 'Resend Confirmation',
      resendConfirmationEmail: 'Resend Confirmation Email',
      resentEmail: 'Email was resent',
      shortPassword: 'Password is too short (minimum is 8 characters)',
      passwordsDoNotMatch: 'Password confirmation does not match',
    },
    projectSidebar: {
      projectBoards: 'Project boards',
      projectMilestones: 'Project milestones',
      projectReports: 'Project reports',
      projectEvaluations: 'Project evaluations',
      lessons: 'Learned lessons',
      tasktracking: 'Project task tracking metrics',
      standUpReports: 'Stand Up Reports',
    },
    tasks: {
      markCompleted: 'Mark as done',
      markUncompleted: 'Mark as uncompleted',
      markCompletedShort: 'Completed',
      markUncompletedShort: 'Uncompleted',
      comment: 'Comment',
      worked: 'Worked',
      estimated: 'Estimated',
      user: 'Users',
      assign: 'Assign',
      label: 'Label',
      report: 'Report',
      archive: 'Archive',
      delete: 'Delete',
      clone: 'Clone',
      assignUser: 'Assign Users',
      hours: 'Hours',
      workDay: 'Work Day',
      addHours: 'Add Hours',
      checklistProgress: 'Checklist',
      noTask: 'There are no available tasks',
    },
    board: {
      addColumn: '+ Add Column',
      addTask: 'Add new task',
      addTaskLabel: 'Task Name',
      addTaskAccept: 'Create',
      addTaskCancel: 'Cancel',
      editColumn: 'Edit',
      deleteColumn: 'Delete',
      archiveTasks: 'Archive tasks',
      completeTasks: 'Complete tasks',
    },
    milestone: {
      addMilestone: 'ADD MILESTONE',
      addTask: 'Add task to milestone',
      empty: 'Name field cannot be empty',
      dateFormat: 'The deadline must ocurr before the start date',
      success: 'Milestone created',
      create: 'Create Milestone',
      edit: 'Edit Milestone',
      delete: 'Delete Milestone',
      name: 'Name',
      start_date: 'Start Date',
      deadline: 'Deadline',
      sprint: 'Sprint',
      deleteSuccess: 'Milestone deleted',
      deleteError: 'The Milestone could not be deleted',
      updateSuccess: 'Milestone updated',
      deleteQuestion: 'Are you sure you want to delete the milestone ',
      yes: 'Yes',
    },
    project: {
      select: 'Select visualization',
      addBoard: '+ Add Board',
      search: 'Search',
      editLabels: 'Edit labels',
      editName: 'Edit name',
      editRoles: 'Edit roles',
      downloadBoardInfo: 'Download board',
      downloadProjectInfo: 'Download project',
      uploadBoardInfo: 'Upload board',
      uploadProjectInfo: 'Upload project',
      deleteBoard: 'Delete board',
      manageInvitations: 'Manage invitations',
      archivedTask: 'Archived tasks',
      notifications: 'Notifications',
      linkGithub: 'Link with Github',
      linkSlack: 'Link with Slack',
      assignRepo: 'Assign Repositories',
      unlinkGithub: 'Unlink Github',
      inviteUser: '+ INVITE USER',
      cancel: 'Cancel',
      create: 'Create',
      boardName: 'Board Name',
      type: 'Visualization type',
      ownTasks: 'My Tasks',
      allTasks: 'All Tasks',
    },
    report: {
      user: 'User',
      users: 'Users',
      hours: 'Hours',
      trazability: 'Tasks',
      tasks: 'Task progress by user',
      completetasks: 'Completed tasks',
      notcompletetasks: 'Uncompleted tasks',
      nocompletetasks: 'There are no completed tasks',
      nonotcompletetasks: 'There are no uncompleted tasks',
      timeline: 'Timeline',
      burnup: 'BurnUp Chart',
      burndown: 'BurnDown Chart',
      pullrequest: 'Pull Requests',
      lead: 'Lead Time',
      commits: 'Commits',
      averagetaskcompletiontime: 'Average task completion time',
      estimatedhoursvariation: 'Task estimated time percentage variation',
      taskprogress: 'Task progress',
      workload: 'Work Load',
      from: 'From',
      to: 'To',
      repository: 'Repository',
      noReportedHours: 'There are no reported hours',
      noReportedHoursInPeriod: 'There are no reported hours on this period',
      noCommitsFound: 'No commits found from this user on that date',
      linkRepositoryFirst: 'Link a repository first to display more information',
      commitsVizTitle: 'Code Frequency by Week',
      commitsVizSubTitle: 'Additions, Deletions and Commits',
    },
    pullRequest: {
      labelX: 'Number of Pull Requests'
    },
    timeline: {
      labelX: 'Date',
      labelY: 'Hours reported',
    },
    reportExplanations: {
      users: "Visualize the hours reported by each user in a board",
      hours: 'Visualize the hours reported by board in a date range',
      trazability: 'Visualize the distribution of completed and uncompleted tasks by user as a treemap',
      timeline: 'Check the progress of the reported hours by each user',
      burnup: 'Track the total work remaining and project the likehood of achieving the sprint goal',
      burndown: 'Track the total scope independently from the total work done',
      pullrequest: 'Visualize the pull requests made by the user of the project',
      lead: 'Check the time taken from commit to deploy by commit',
      commits: 'Check the commits made by user and the additions and deletions per commit',
      averagetaskcompletiontime: 'Check the average completion time of the tasks in a board',
      estimatedhoursvariation: 'Visualize the percentage variation of the task estimation time by board',
      taskprogress: 'Compare the reported hours by user and the estimated hours per task by board',
      workload: 'Visualize each user’s workload by board given a time range',
    },
    burnchart: {
      user: 'User',
      burnup: 'BurnUp Chart',
      burndown: 'BurnDown Chart',
      milestoneSelection: 'Select milestone',
      sprintSelection: 'Select sprint',
      estimated_hours: 'Ideal Progress',
      reported_hours: 'Actual Progress',
      goal: 'Goal',
      hours: 'Hours',
      days: 'Days',
      noReportedHoursInMilestone: 'There are no reported hours on this milestone',
      noMilestoneSelected: 'There is no milestone selected',
      noSprintSelected: 'There is no sprint selected',
    },
    tasktracking: {
      averagetaskcompletiontime: 'Average task completion time',
      estimatedhoursvariation: 'Task estimated time variation',
      taskprogress: 'Task progress',
      noReportedHours: 'There are no reported hours',
    },
    home: {
      folders: 'Folders',
      addFolder: 'ADD FOLDER',
      addProject: 'ADD PROJECT',
      archivedFolders: 'ARCHIVED FOLDERS',
      downloadProjects: "DOWNLOAD PROJECTS",
      uploadProjects: "UPLOAD PROJECT",
      noProjectSelected: 'No project selected',
      favoriteProjects: 'Favorite Projects',
      noFavoriteProjects: "You don't have any favorite projects yet",
      oneBoard: '1 Board',
      boards: 'Boards',
      project: 'Project',
      projects: 'Projects',
      editName: 'Edit name',
      removeFavorites: 'Remove from favorites',
      activity: 'Activity',
      noActivities: 'There is no activity',
      loading: 'Loading',
      canNotRemoveFolder: 'Cannot remove folder with projects inside',
      createProject: 'Create new project',
      editFolder: 'Edit Folder',
      archiveFolder: 'Archive Folder',
      deleteFolder: 'Delete Folder',
      archiveFolder: 'Archive Folder',
      archivedFolders: 'Archived Folders',
      deleteProject: 'Delete Project',
      addToFavorites: 'Add to favorites',
      empty: 'Empty',
      showActivity: 'Show Activity',
      hideActivity: 'Hide Activity',
      deleteInvitation: 'You have deleted the invitation successfully',
      invitations: 'Invitations:',
      hasInvited: 'Has invited you to',
      asA: 'as a',
      noInvitations: 'There are no invitations left',
    },
    modals: {
      sureDeleteProject: 'Are you sure you want to delete this project? ',
      deleteProject: 'Delete project',
      projectDeleted: 'Project deleted',
      sureChangeDeadline1: 'Are you sure you want to change the deadline to ',
      sureChangeDeadline2: '?',
      deleteColumn: 'Delete column',
      yes: 'Yes',
      no: 'No',
      columnWithTasks: 'This column has tasks in it.',
      columnWithArchivedTasks: 'This column has archived tasks in it.',
      sureDeleteColumn: 'Are you sure you want to delete column',
      editColumnConfirm: 'CONFIRM',
      editColumnNameLabel: 'Name',
      editColumnTitle: 'Edit Column',
      taskModalEditLabelModalCreate: 'Create new label',
      taskModalEditLabelModalCreateButton: 'CREATE',
      taskModalEditLabelModalCreateNameLabel: 'Label Name',
    },
    hoursIndex: {
      hoursChart: 'Hours Chart',
      from: 'From:',
      to: 'To:',
      filterBy: 'Filter by:',
      totalHours: 'Total hours',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      year: 'Year',
      noReported: 'There are no reported hours on this period.',
      maxFilterDay: 'You can visualize a maximum of 30 days.',
      maxFilterWeek: 'You can visualize a maximum of 2 months.',
      maxFilterMonth: 'You can visualize a maximum of 1 year.',
      maxFilterYear: 'You can visualize a maximum of 6 years.',
    },
    dynamicChart: {
      totalHours: 'Total hours',
      labelReportedHours: 'Hours reported by label',
      boardReportedHours: 'Hours reported by board',
      reset: 'Reset',
      clickMessage: 'Click to see activity filtered by label.',
    },
    averageTaskTimeChart: {
      averageHours: 'Average Hours',
      boardAverageHours: 'Average Task Completion Time by Board',
      hours: 'Hours',
      totalTasks: 'Total Tasks',
      averageTime: 'Average Time',
      milestoneSelection: 'Select milestone',
      noReportedHoursInMilestone: 'There are no reported hours on this milestone',
      noMilestoneSelected: 'There is no milestone selected',
      totalCompletedTasks: 'Number of Completed Tasks',
    },
    percentageRangeChart: {
      title: 'Percentage variation of task estimation time by board',
      yAxis: 'Percentage ( % )',
      percentage: 'Percentage variation',
      average: 'Average',
      overestimation: 'Overestimation',
      underestimation: 'Underestimation',
    },
    taskProgressChart: {
      title: 'Task Progress',
      yAxis: 'Total Hours Worked',
      estimated_hours: 'Estimated Hours',
      boardSelection: 'Select Board',
      noTasksonBoard: 'There are no tasks on this board',
      noBoardSelected: 'There is no board selected',
      from: 'From',
      to: 'To',
    },
    WorkLoadChart: {
      project: 'All Boards',
      title: 'Work Load',
      yAxis: 'Total Estimated Hours',
      estimated_hours: 'Total Estimated Hours',
      boardSelection: 'Select Board',
      noTasksonBoard: 'There are no assigned tasks on this board',
      noBoardSelected: 'There is no board selected',
      from: 'From',
      to: 'To',
    },
    userHoursChart: {
      totalHours: 'Total hours',
      chartTitle: 'Hours reported by each user in each board',
    },
    usersChartIndex: {
      hoursChart: 'Users Hours Chart',
      from: 'From:',
      to: 'To:',
      filterBy: 'Filter by:',
      totalHours: 'Total hours by user',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      year: 'Year',
      noReported: 'There are no reported hours on this period.',
    },
    evaluations: {
      backEvaluations: '< Back to Evaluations',
      backResults: '< Back to Results',
      evaluations: 'Evaluations',
      evaluation: 'Evaluation',
      results: 'Results',
      management: 'Management',
      projectEvaluation: 'Project Evaluation',
      userEvaluation: 'Team Evaluation',
      deadlineLabel: 'Deadline: ',
      initialDateLabel: 'Publication: ',
      noTitle: 'No title',
      noType: 'No type',
      noDeadline: 'No deadline',
      dateDisplay: 'en-GB',
      noEvaluationsAvailable: 'There are no evaluations available',
      sent: 'Sent',
      submit: 'Submit',
      save: 'Save',
      noQuestions: 'No questions',
      answerSent: 'Your answer was successfully sent',
      answerSave: 'Your answer was successfully saved',
      members: 'Members',
      noMembers: 'No members',
      notImplemented: 'Not implemented',
      title: 'Evaluation title',
      type: 'Evaluation type: ',
      userType: 'Team',
      projectType: 'Project',
      description: 'Description: ',
      noMembersSelected: 'No members selected',
      selectMembers: 'Select members',
      next: 'Next',
      previous: 'previous',
      sendEvaluation: 'send evaluation',
      fieldsNotEmpty: 'Fields can not be empty',
      membersNotEmpty: 'Members can not be empty',
      atLeast2Members: 'In team evaluations you have to select at least 2 members',
      questionsNotEmpty: 'Questions can not be empty',
      createdSuccessfully: 'You created the evaluation successfully',
      membersLabel: 'Members',
      closeButton: 'Close',
      questions: 'Questions',
      evaluate: 'Evaluate',
      seeEvaluation: 'Evaluation',
      answersLabel: 'Answers',
      editDeadline: 'Edit deadline',
      deadlineBeforeInitialDate: 'Deadline can not be before publication date',
    },
    answers: {
      answers: 'Answers',
      percentage: 'Amount',
      alternative: 'Alternative',
      noAnswers: 'This evaluation has no answers.',
    },
    activity: {
      in: 'in',
      to: 'to',
      'has edited': 'has edited',
      'has created': 'has created',
      'has deleted': 'has deleted',
      'has moved': 'has moved',
      'has assigned': 'has assigned',
      'has commented in': 'has commented',
    },
    evaluationsManagement: {
      createEvaluation: 'New evaluation',
      cancel: 'Cancel',
      activeEvaluations: 'Active Evaluations: ',
      recently: 'Recently expired: ',
      changeDeadlineTitle: 'Change deadline',
      save: 'Save',
      successMessage: 'Deadline successfully updated',
      sendEvaluation: 'Send evaluation',
      areYouSure: 'Are you sure you want to send the evaluation?',
      cantUndo: 'You cannot undo this action',
      no: 'no',
      yes: 'yes',
    },
    lessons: {
      search: 'Search',
      create: 'Create',
      send: 'Send',
      title: 'Title',
      problem: 'Problem',
      solution: 'Solution',
      lessons: 'Lessons',
      created: 'Lesson created!',
      emptyField: 'Field can not be empty',
      repeatedTag: 'Tags can not be repeated',
      maximumTags: 'You can select a maximum of 5 tags',
      searchBar: 'Search for lessons ...',
      results: 'Search results',
      noResults: 'No results available',
      learnMore: 'Learn More',
      backToList: 'Back to list',
      inlineTags: 'Tags',
      previousPage: 'Previous',
      nextPage: 'Next',
      page: 'Page',
      myLessons: 'My Lessons',
      manageLessons: 'Manage',
      remove: 'Remove',
      removed: 'Lesson successfully removed',
      edit: 'Edit',
      save: 'Save',
      cancel: 'Cancel',
      saved: 'Lesson saved',
      published: 'Published',
      notPublished: 'Not published yet',
      votes: 'Votes',
      like: 'Like',
      dislike: 'Dislike',
    },
    standUpReports: {
      reports: 'Reports',
      alerts: 'Alerts',
      analysis: 'Analysis',
      group: "Group",
      myReports: 'My Reports',
      update: 'Update',
      edit: 'Edit',

      
      // here goes EN standupreports 
    },
    manageLessons: {
      title: 'Title',
      published: 'Published',
      publisher: 'Email Publisher',
      creation: 'Creation date',
      tags: 'Tags',
      actions: 'Actions',
      votes: 'Votes',
      views: 'Views',
      abuse: 'Abuse',
      off: 'No',
      on: 'Yes',
      approved: 'Lesson approved',
      disapproved: 'Lesson disapproved',
      show: 'Show lesson',
      edit: 'Edit lesson',
      remove: 'Remove lesson',
      filters: 'Filters',
      author: 'Email',
      thisProject: 'This project',
      date: 'Semester',
      dates: ['2022-2', '2022-1', '2021-2', '2021-1', '2020-2', '2020-1', '2019-2', '2019-1', '2018-2', '2018-1'],
      tag: 'Tag',
      none: 'No filter',
      search: 'Search',
    },
    roleEdit: {
      modalTitle: 'Edit roles',
      close: 'Close',
      noUsers: 'There are no users in this project',
      removeMemberTitle: 'Remove member from project?',
      removeMemberDescription: 'By doing this, you will remove the selected member from this project.',
      removeMemberCancel: 'Cancel',
      removeMemberAccept: 'Remove member',
    },
    gantt: {
      task: 'Tasks',
      datetime: {
        months: [
          "January", "February", "March",
          "April", "May", "June", "July",
          "August", "September", "October",
          "November", "December"],
        weekdays: [
          "Sunday", "Monday", "Tuesday",
          "Wednesday", "Thursday", "Friday",
          "Saturday"],
        shortMonths: [
          "Jan", "Feb", "Mar", "Apr",
          "May", "Jun", "Jul", "Aug",
          "Sep", "Oct", "Nov", "Dec"]
      },
      week: "Week %W",
      start: 'Start: ',
      end: 'End: '
    },
    engine: {
      change: "Change Engine",
      actually: "Actually Using",
      select: "Select Engine",
      metric: "Metric",
      change_button: "Change",
      change_success: "Engine changed successfully",
      metric_title: "Evaluation Metrics",
      coverage: "Coverage",
    },
    myProfile: {
      previous: "Previous Knowledge",
      finals: "Final Knowledge",
      instructions: "Answer the following form according to your level of knowledge in the area of software development.",
      form: "Form",
      open: "Opened until",
      de: "of",
      formClosed: "Form closed. Expired on",
      open_second: "It has been opened since",
      de: "The",
      formClosedSecond: "Form closed. The",
      edition: "will be open for edition",
    },
  },
  es: {
    bot: {
      headerText: 'ZBot',
      placeholderText: 'Haz una consulta',
      title: "Conversación con Zbot",
      firstMessage: "Hola, soy Zbot, el asistente virtual de ZmartBoard. ¿Cómo puedo ayudarte? Siempre puedes escribir 'menú' para ver las opciones",
      evaluations: "Entregas",
      teachingTeam: "Equipo de Enseñanza",
      recomendation: "Quiero una recomendación",
      templates: "Plantillas",
      chat: {
        firstMessage: "Ingresa tu consulta, para obtener mejores resultados intenta ser lo más específico posible incluyendo tecnologías, lenguajes, contexto, etc.",
        title: "Recomendaciones de Zbot",
        placeholder: "Ingresa tu consulta",
        send: "Enviar",
        isGenerating: "Las respuestas se están generando, puedes verlas moviéndote entre las diferentes pestañas. Esto puede tardar unos segundos debido al procesamiento realizado por el motor basado en IA desarrollado para proporcionar los diferentes consejos.",
        errorMessage: 'Se ha producido un error en el motor de procesamiento basado en IA que provee el servicio cognitivo para el desarrollo de este Tip, por favor intenta nuevamente.',
        feedbackThanks: "¡Gracias por tus comentarios!",
        feedbackQuestion: "¿Te resultó útil la respuesta?",
        feedbackYes: "Sí",
        feedbackNo: "No",
      },
    },
    analysis: {
      time: 'Tiempo',
      stress: 'Estrés',
      blocking: 'Bloqueo',
      feel: 'Sentimiento',
      time_text: 'Indica si el integrante tubo problemas de tiempo en la semana y que tan graves fueron. Mientrás más cercano a 1, más grave.',
      stress_text: 'Indica si el integrante tubo problemas de estrés en la semana y que tan graves fueron. Mientrás más cercano a 1, más grave.',
      blocking_text: 'Indica si el integrante tubo problemas de bloqueo (se enfrentó a un problema que le impidió avanzar) en la semana y que tan graves fueron. Mientrás más cercano a 1, más grave.',
      feel_text: 'Indica si lo reportado por el integrante tiene un sentimiento positivo o negativo. Por ejemplo: "tuve tiempo para descansar" es un sentimiento positivo, mientras que "no tuve tiempo por los exámenes" es un sentimiento negativo. Mientras más cercano 0, más grave.',
      member: 'Integrante',
      title: 'Información sobre el análisis'
    },
    metrics: {
      rmse_text: 'Es la distancia entre el puntaje asignado a la recomendación evaluada por el usuario y la recomendación hecha por el sistema recomendador. Un menor valor es mejor.',
      rmsd_text: 'En este caso se buscó determinar qué tan lejos se encontraban las recomendaciones de cada engine de las recomendaciones evaluadas con 1 estrella por los usuarios. Un mayor valor es mejor.',
      coverage: 'Cobertura',
      coverage_text: 'Es el porcentaje de las lecciones disponibles que efectivamente se recomiendan alguna vez a un usuario.',
      prediction_text: 'Las métricas de predicción de calificación buscan medir qué tan cerca están las calificaciones estimadas por un recomendador de las verdaderas calificaciones de los usuarios. En esta categoría están RMSE, NRMSE, RMSD y NRMSD',
      no_prediction: 'Otras métricas',
      prediction: 'Métricas predictivas',
      title: 'Información de la métricas',
    },
    settings: {
      editProfile: 'Editar perfil',
      editSlackBot: 'Editar parámetros del SlackBot',
      uploadPicture: 'Subir imagen',
      submit: 'Actualizar',
      name: 'Nombre',
      github: 'GitHub',
      labname: 'Gitlab',
      year: 'Año',
      semester: 'Semestre',
      section: 'Sección',
      group: 'Grupo',
      newPass: 'Nueva contraseña',
      confirmPass: 'Confirmar contraseña',
      currentPass: 'Contraseña actual',
      notifySuccess: 'Tu cuenta se actualizó exitosamente',
      notifyError: 'Error en la confirmación de contraseña',
      editDates: 'Editar fechas',
      editTeams: 'Editar equipos',
      editQuestions: 'Editar Preguntas'
    },
    dashboard: {
      editChatBot: 'Editar parámetros del ChatBot',
      chatbotEdit: {
        name: 'Nombre',
        work: 'Trabajo',
        section: 'Sección',
        group: 'Grupo',
        email: 'Email',
        semester: 'Semestre',
        year: 'Año',
        actions: 'Acciones',
        create: 'Crear',
        search: 'Buscar',
        selectYear: 'Selecciona Año',
        selectSem: 'Selecciona Semestre',
        cicle: 'Ciclo',
        date: 'Fecha',
        updateCicles: 'Actualizar Ciclos',
        edit: 'Editar',
        delete: 'Eliminar',
        update: 'Actualizar',
      },
      metric: {
        evaluation: 'Métricas de Evaluación',
        metric: 'Métrica',
        cosine: 'Métrica Coseno',
        lev: 'Métrica Levenshtein',
        pond: 'Métrica Ponderado',
        coverage: 'Cobertura',
        system: 'Sistema recomendador',
      },
      dashboardTitle: 'Panel de administrador',
      editTerms: 'Editar Términos y Condiciones',
      nameTermsConditions: 'Términos y Condiciones',
      history: 'Historial',
      editSuccess: "Cambio Exitoso",
      deleteSuccess: "Usuario Eliminado",
      askConfirmation: "¿Esta seguro que quiere eliminar al usuario?",
      cannotChange: "No se puede editar",
      uploadTerms: "Subir archivo",
      termsHistory: {
        createdAt: "Fecha Creación",
        language: "Idioma",
        name: "Nombre",
        download: "Descargar",
      },
      unset: 'Sin archivo disponible',
      recommendations: "Sistemas Recomendadores",
      user: "Usuarios",
      terms: "Términos y Condiciones",
      users: {
        filterBar: "Barra de Filtro",
        listLength: "Longitud Lista",
        orderBy: "Ordenar por",
        name: "Nombre",
        lastSignIn: "Último Sign In",
        orderSense: "Sentido Orden",
        searchButton: "Buscar",
        photo: "Foto",
        semester: "Semestre",
        section: "Sección",
        group: "Grupo",
        year: "Año",
        projects: "Proyectos",
        actions: "Acciones",
        last: "Anterior",
        next: "Siguiente",
        edit: "Editar",
        delete: "Borrar",
        submit: "Aplicar",
      },
    },
    share: 'Compartir URL',
    app: {
      noConnection: 'No hay conexión a internet disponible',
      uploadComplete: 'Archivo subido correctamente',
    },
    date_change: {
      success: 'Se ha cambiado las fechas correctamente.',
    },
    login: {
      confirmRegistration:
        'Tienes que confirmar tu email para finalizar tu registro',
      confirmPassword: 'Confirma tu contraseña',
      welcomeTo: 'Bienvenido a',
      login: 'Iniciar sesión',
      signin: 'Entrar',
      register: 'Registrate',
      incompleteCred: 'Credenciales incompletas',
      email: 'Email',
      emailNotValid: 'Email no es válido',
      termsNotAccepted: 'Debes aceptar los terminos y condiciones',
      password: 'Contraseña',
      forgotPass: 'Olvidé la contraseña',
      googleLogin: 'Iniciar sesión con Google',
      resetPasswordEmailSent:
        'Se ha enviado un email para resetear su contraseña',
      resetPassword: 'Resetear contraseña',
      resend: 'Reenviar',
      resendConfirmation: 'Reenviar confirmación',
      resendConfirmationEmail: 'Reenviar confirmación de email',
      resentEmail: 'Email ha sido reenviado',
      shortPassword: 'Contraseña muy corta (minimo de 8 caracteres)',
      passwordsDoNotMatch: 'Contraseña de confirmación no coincide',
    },
    projectSidebar: {
      projectBoards: 'Tableros del proyecto',
      projectMilestones: 'Milestones del proyecto',
      projectReports: 'Reportes del proyecto',
      projectEvaluations: 'Evaluaciones del proyecto',
      projectBurnCharts: 'Gráficos BurnUp/BurnDown del Proyecto',
      lessons: 'Lecciones aprendidas',
      standUpReports: 'Reportes StandUp',
      tasktracking: 'Métricas de seguimiento de tareas del proyecto',
    },
    tasks: {
      markCompleted: 'Marcar como completado',
      markUncompleted: 'Marcar como incompleto',
      markCompletedShort: 'Completado',
      markUncompletedShort: 'Incompleto',
      comment: 'Comentar',
      worked: 'Trabajado',
      estimated: 'Estimado',
      user: 'Usuarios',
      assign: 'Asignar',
      label: 'Etiquetar',
      report: 'Reportar',
      archive: 'Archivar',
      delete: 'Eliminar',
      clone: 'Clonar',
      assignUser: 'Asignar Usuarios',
      hours: 'Horas',
      workDay: 'Dia Trabajado',
      addHours: 'Agregar Horas',
      checklistProgress: 'Subtareas',
      noTask: 'No hay tareas disponibles',
    },
    board: {
      addColumn: '+ Agregar Columna',
      addTask: 'Agregar nueva tarea',
      addTaskLabel: 'Nombre de la Tarea',
      addTaskAccept: 'Crear',
      addTaskCancel: 'Cancelar',
      editColumn: 'Editar',
      deleteColumn: 'Eliminar',
      archiveTasks: 'Archivar tareas',
      completeTasks: 'Completar tareas',
    },
    project: {
      select: 'Seleccionar visualización',
      addBoard: '+ Agregar Tablero',
      search: 'Buscar',
      editLabels: 'Editar etiquetas',
      editName: 'Editar nombre',
      downloadBoardInfo: 'Descargar tablero',
      downloadProjectInfo: 'Descargar proyecto',
      uploadBoardInfo: 'Subir tablero',
      uploadProjectInfo: 'Subir proyecto',
      deleteBoard: 'Eliminar tablero',
      editRoles: 'Editar roles',
      manageInvitations: 'Manejar invitaciones',
      archivedTask: 'Archivar tareas',
      notifications: 'Notificaciones',
      linkGithub: 'Conectar con Github',
      assignRepo: 'Asignar repositorios',
      unlinkGithub: 'Desvincular Github',
      inviteUser: '+ INVITAR USUARIO',
      cancel: 'Cancelar',
      create: 'Crear',
      boardName: 'Nombre del Tablero',
      type: 'Tipo de visualización',
      ownTasks: 'Mis Tareas',
      allTasks: 'Todas las Tareas',
    },
    milestone: {
      addMilestone: 'CREAR MILESTONE',
      addTask: 'Añadir tarea a milestone',
      empty: 'El campo "Nombre" no debe estar vacío',
      dateFormat: 'La fecha de término debe ser antes que la fecha de inicio',
      success: 'Milestone creado',
      create: 'Crear Milestone',
      edit: 'Editar Milestone',
      delete: 'Eliminar Milestone',
      name: 'Nombre',
      start_date: 'Fecha de Inicio',
      deadline: 'Fecha de Término',
      sprint: 'Sprint',
      deleteSuccess: 'Milestone eliminado',
      deleteError: 'El milestone no se ha podido eliminar',
      updateSuccess: 'Milestone actualizado',
      deleteQuestion: '¿Estás seguro que deseas eliminar el milestone ',
      yes: 'Sí',
    },
    report: {
      user: 'Usuario',
      users: 'Usuarios',
      hours: 'Horas',
      trazability: 'Tareas',
      tasks: 'Progreso de tareas por usuario',
      completetasks: 'Tareas completadas',
      notcompletetasks: 'Tareas no completadas',
      nocompletetasks: 'No hay tareas completadas',
      nonotcompletetasks: 'No hay tareas no completadas',
      timeline: 'Linea de tiempo',
      burnup: 'Gráfico BurnUp',
      burndown: 'Gráfico BurnDown',
      pullrequest: 'Pull Requests',
      lead: 'Lead Time',
      commits: 'Commits',
      averagetaskcompletiontime: 'Tiempo promedio de finalización de tareas',
      estimatedhoursvariation: 'Variación de tiempo estimado de tareas',
      taskprogress: 'Progreso de tareas',
      workload: 'Carga de trabajo',
      from: 'Desde',
      to: 'Hasta',
      repository: 'Repositorio',
      noReportedHours: 'No hay horas reportadas',
      noReportedHoursInPeriod: 'No hay horas reportadas en este periodo',
      noCommitsFound: 'No se encontraron commits de este usuario en la fecha indicada',
      linkRepositoryFirst: 'Primero vincula un repositorio para ver mas información',
      commitsVizTitle: 'Frecuencia de Código por semana',
      commitsVizSubTitle: 'Additions, Deletions y Commits',
    },
    reportExplanations: {
      users: "Visualiza las horas reportadas por cada usuario en un tablero",
      hours: 'Visualiza las horas reportadas por cada tablero',
      trazability: 'Visualiza la distribución de tareas completas e incompletas por usuario como un treemap',
      timeline: 'Chequea el progreso de las horas reportadas por cada usuario',
      burnup: 'Rastrea el trabajo total restante y proyecta la probabilidad de alcanzar la meta del sprint',
      burndown: 'Rastrea el alcance total independiente del trabajo total realizado',
      pullrequest: 'Visualiza los pull requests hechos por los usuarios conectados al repositorio',
      lead: 'Chequea el tiempo que demora el commit hasta el deploy',
      commits: 'Chequea los commits hechos por usuario y las adiciones y deleciones por commit',
      averagetaskcompletiontime: 'Chequea el tiempo promedio de tareas por tablero',
      estimatedhoursvariation: 'Visualiza la variación porcentual del tiempo estimado de las tareas por tablero',
      taskprogress: 'Compara las horas reportadas por usuario con las horas estimadas para cada tarea',
      workload: 'Visualiza la carga de trabajo de cada usuario por tablero',
    },
    pullRequest: {
      labelX: 'Numero de Pull Requests'
    },
    timeline: {
      labelX: 'Fecha',
      labelY: 'Horas reportadas',
    },
    burnchart: {
      user: 'Usuario',
      burnup: 'Gráfico BurnUp',
      burndown: 'Gráfico BurnDown',
      milestoneSelection: 'Selecciona un milestone',
      sprintSelection: 'Selecciona un sprint',
      estimated_hours: 'Progreso Ideal',
      reported_hours: 'Progreso Actual',
      goal: 'Objetivo',
      hours: 'Horas',
      days: 'Días',
      noReported: '',
      noReportedHoursInMilestone: 'No hay horas reportadas en este milestone',
      noMilestoneSelected: 'No hay milestone seleccionado',
      noSprintSelected: 'No hay sprint seleccionado',
    },
    tasktracking: {
      averagetaskcompletiontime: 'Tiempo promedio de finalización de tareas',
      estimatedhoursvariation: 'Porcentaje de variación de tiempo estimado de tareas',
      taskprogress: 'Progreso de tareas',
      noReportedHours: 'No hay horas reportadas',
    },
    home: {
      folders: 'Carpetas',
      addFolder: 'CREAR CARPETA',
      addProject: 'CREAR PROJECTO',
      archivedFolders: 'CARPETAS ARCHIVADAS',
      downloadProjects: "DESCARGAR PROYECTOS",
      uploadProjects: "SUBIR PROYECTO",
      noProjectSelected: 'No hay proyecto seleccionado',
      favoriteProjects: 'Proyectos favoritos',
      noFavoriteProjects: 'Aún no tienes proyectos favoritos',
      oneBoard: '1 Tablero',
      boards: ' Tableros',
      project: 'Proyecto',
      projects: 'Proyectos',
      editName: 'Editar nombre',
      removeFavorites: 'Eliminar de favoritos',
      activity: 'Actividades',
      noActivities: 'No hay actividades',
      loading: 'Cargando',
      canNotRemoveFolder: 'No puedes borrar carpetas que contengan proyectos',
      createProject: 'Crear nuevo proyecto',
      editFolder: 'Editar carpeta',
      archiveFolder: 'Archivar carpeta',
      deleteFolder: 'Eliminar carpeta',
      archiveFolder: 'Archivar Carpeta',
      archivedFolders: 'Carpetas Archivadas',
      deleteProject: 'Eliminar proyecto',
      addToFavorites: 'Agregar a favoritos',
      empty: 'Vacío',
      showActivity: 'Mostrar actividad',
      hideActivity: 'Ocultar actividad',
      deleteInvitation: 'Has eliminado la invitación correctamente',
      invitations: 'Invitaciones:',
      hasInvited: 'Te ha invitado a',
      asA: 'como',
      noInvitations: 'No hay invitaciones',
    },
    modals: {
      sureDeleteProject: '¿Estás seguro de que deseas borrar el proyecto? ',
      deleteProject: 'Borrar proyecto',
      projectDeleted: 'Proyecto borrado',
      sureChangeDeadline1: '¿Estás seguro de que deseas cambiar la fecha límite a ',
      sureChangeDeadline2: '?',
      deleteColumn: 'Eliminar columna',
      yes: 'Sí',
      no: 'No',
      columnWithTasks: 'Esta columna tiene tareas',
      columnWithArchivedTasks: 'Esta columna tiene tareas archivadas',
      sureDeleteColumn: '¿Estás seguro de que quieres eliminar la columna',
      editColumnConfirm: 'CONFIRMAR',
      editColumnNameLabel: 'Nombre',
      editColumnTitle: 'Editar Columna',
      taskModalEditLabelModalCreate: 'Crear nueva etiqueta',
      taskModalEditLabelModalCreateButton: 'CREAR',
      taskModalEditLabelModalCreateNameLabel: 'Nombre Etiqueta',
    },
    hoursIndex: {
      hoursChart: 'Gráfico de horas reportadas',
      from: 'Desde:',
      to: 'Hasta:',
      filterBy: 'Filtrar por:',
      totalHours: 'Horas totales',
      day: 'Día',
      week: 'Semana',
      month: 'Mes',
      year: 'Año',
      noReported: 'No hay horas reportadas en ese periodo',
      maxFilterDay: 'Solo se puede visualizar un máximo de 30 días.',
      maxFilterWeek: 'Solo se puede visualizar un máximo de 2 meses.',
      maxFilterMonth: 'Solo se puede visualizar un máximo de 1 año.',
      maxFilterYear: 'Solo se puede visualizar un máximo de 6 años.',
    },
    dynamicChart: {
      totalHours: 'Horas totales',
      labelReportedHours: 'Horas reportadas por etiqueta',
      boardReportedHours: 'Horas reportadas por tablero',
      reset: 'Restablecer',
      clickMessage: 'Haz click para ver el detalle por etiqueta.',
    },
    averageTaskTimeChart: {
      averageHours: 'Horas Promedio',
      boardAverageHours: 'Tiempo Promedio de Tareas por Tablero',
      hours: 'Horas',
      totalTasks: 'Tareas Completadas',
      averageTime: 'Tiempo Promedio',
      milestoneSelection: 'Selecciona un milestone',
      noReportedHoursInMilestone: 'No hay horas reportadas en este milestone',
      noMilestoneSelected: 'No hay milestone seleccionado',
      totalCompletedTasks: 'Total de Tareas Completadas',
    },
    percentageRangeChart: {
      title: 'Variación porcentual del tiempo estimado de las tareas por tablero',
      yAxis: 'Porcentaje ( % )',
      percentage: 'Variación porcentual',
      average: 'Promedio',
      overestimation: 'Sobrestimación',
      underestimation: 'Subestimación',
    },
    taskProgressChart: {
      title: 'Progreso de tareas',
      yAxis: 'Horas Totales Trabajadas',
      estimated_hours: 'Horas Estimadas',
      boardSelection: 'Selecciona un tablero',
      noTasksonBoard: 'No hay tareas en este board',
      noBoardSelected: 'No hay tablero seleccionado',
      from: 'Desde',
      to: 'Hasta',
    },
    WorkLoadChart: {
      project: 'Todos los tableros',
      title: 'Carga de trabajo',
      yAxis: 'Horas Totales Estimadas',
      estimated_hours: 'Total Horas Estimadas',
      boardSelection: 'Selecciona un tablero',
      noTasksonBoard: 'No hay tareas asignadas en este board',
      noBoardSelected: 'No hay tablero seleccionado',
      from: 'Desde',
      to: 'Hasta',
    },
    userHoursChart: {
      totalHours: 'Horas totales',
      chartTitle: 'Horas por usuario reportadas en cada tablero',
    },
    usersChartIndex: {
      hoursChart: 'Gráfico de horas reportadas por usuario',
      from: 'Desde:',
      to: 'Hasta:',
      filterBy: 'Filtrar por:',
      totalHours: 'Horas totales por usuario',
      day: 'Día',
      week: 'Semana',
      month: 'Mes',
      year: 'Año',
      noReported: 'No hay horas reportadas en ese periodo',
    },
    evaluations: {
      backEvaluations: '< Atras a Evaluaciones',
      backResults: '< Atras a Resultados',
      evaluations: 'Evaluaciones',
      evaluation: 'Evaluación',
      results: 'Resultados',
      management: 'Administración',
      projectEvaluation: 'Evaluación de proyecto',
      userEvaluation: 'Evaluación de equipo',
      deadlineLabel: 'Fecha límite: ',
      initialDateLabel: 'Fecha de inicio: ',
      noTitle: 'Sin título',
      noType: 'Sin tipo',
      noDeadline: 'Sin fecha límite',
      dateDisplay: 'es-US',
      noEvaluationsAvailable: 'No hay evaluaciones disponibles',
      sent: 'Enviada',
      submit: 'Enviar',
      save: 'Guardar',
      noQuestions: 'No hay preguntas',
      answerSent: 'Tu respuesta fue enviada con éxito',
      answerSave: 'Tu respuesta fue guardada con éxito',
      members: 'Miembros',
      noMembers: 'No hay miembros',
      notImplemented: 'No implementado',
      title: 'Título de la evaluación',
      type: 'Tipo de evaluación: ',
      userType: 'Equipo',
      projectType: 'Proyecto',
      description: 'Descripción: ',
      noMembersSelected: 'No has seleccionado miembros',
      selectMembers: 'Seleccionar miembros',
      next: 'Siguiente',
      previous: 'Anterior',
      sendEvaluation: 'Enviar evaluación',
      fieldsNotEmpty: 'No puedes dejar campos vacíos',
      membersNotEmpty: 'Debes seleccionar miembros',
      atLeast2Members: 'Para evaluaciones de equipo debes seleccionar al menos dos miembros',
      questionsNotEmpty: 'Debes agregar al menos una pregunta',
      createdSuccessfully: 'Creaste una evaluación con éxito',
      membersLabel: 'Miembros',
      closeButton: 'Cerrar',
      questions: 'Preguntas',
      evaluate: 'Evaluar',
      seeEvaluation: 'Evaluación',
      answersLabel: 'Respuestas',
      editDeadline: 'Editar fecha límite',
      deadlineBeforeInitialDate: 'La fecha de inicio debe ser anterior a la fecha límite',
    },
    answers: {
      answers: 'Respuestas',
      percentage: 'Cantidad',
      alternative: 'Alternativas',
      noAnswers: 'Esta evaluación no tiene respuestas.',
    },
    activity: {
      in: 'en',
      to: 'a',
      'has edited': 'ha editado',
      'has created': 'ha creado',
      'has deleted': 'ha borrado',
      'has moved': 'ha movido',
      'has assigned': 'ha asignado',
      'has commented in': 'ha comentado',
    },
    evaluationsManagement: {
      createEvaluation: 'Nueva evaluación',
      cancel: 'Cancelar',
      activeEvaluations: 'Evaluaciones activas: ',
      recently: 'Recientemente vencidas: ',
      changeDeadlineTitle: 'Cambiar fecha límite',
      save: 'Guardar',
      successMessage: 'Fecha límite actualizada con éxito',
      sendEvaluation: 'Enviar evaluación',
      areYouSure: '¿Estás seguro de que deseas enviar la evaluación?',
      cantUndo: 'No puedes deshacer está acción',
      no: 'no',
      yes: 'sí',
    },
    lessons: {
      search: 'Buscar',
      create: 'Crear',
      send: 'Enviar',
      title: 'Título',
      problem: 'Problema',
      solution: 'Solución',
      lessons: 'Lecciones',
      created: 'Lección creada!',
      emptyField: 'Campo no puede estar vacío',
      repeatedTag: 'No puedes repetir etiquetas',
      maximum: 'Puedes seleccionar un máximo de 5 tags',
      searchBar: 'Buscar lecciones ...',
      results: 'Resultados de búsqueda',
      noResults: 'No hay resultados disponibles',
      learnMore: 'Ver más',
      backToList: 'Volver',
      inlineTags: 'Etiquetas',
      previousPage: 'Anterior',
      nextPage: 'Siguiente',
      page: 'Página',
      myLessons: 'Mis Lecciones',
      manageLessons: 'Administrar',
      remove: 'Borrar',
      removed: 'Lección eliminada con éxito',
      edit: 'Editar',
      save: 'Actualizar',
      cancel: 'Cancelar',
      saved: 'Lección actualizada',
      published: 'Publicada',
      notPublished: 'No publicada aún',
      votes: 'Votos',
      like: 'Me gusta',
      dislike: 'No me gusta',
    },
    standUpReports: {
      reports: 'Reportes',
      alerts: 'Alertas',
      group: "Grupo",
      myReports: 'Mis Reportes',
      update: 'Actualizar',
      edit: 'Editar',
      analysis: "Análisis",
    },
    manageLessons: {
      title: 'Título',
      published: 'Aprobada',
      publisher: 'Email Autor',
      creation: 'Fecha de creación',
      tags: 'Etiquetas',
      actions: 'Acciones',
      votes: 'Votos',
      views: 'Vistas',
      abuse: 'Abusos',
      off: 'No',
      on: 'Sí',
      approved: 'Lección aprobada',
      disapproved: 'Lección desaprobada',
      show: 'Ver lección',
      edit: 'Editar lección',
      remove: 'Borrar lección',
      filters: 'Filtros',
      author: 'Email',
      thisProject: 'Este proyecto',
      date: 'Semestre',
      dates: ['2022-2', '2022-1', '2021-2', '2021-1', '2020-2', '2020-1', '2019-2', '2019-1', '2018-2', '2018-1'],
      tag: 'Etiqueta',
      none: 'Sin filtro',
      search: 'Buscar',
    },
    roleEdit: {
      modalTitle: 'Editar roles',
      close: 'Cerrar',
      noUsers: 'No hay usuarios en este proyecto.',
      removeMemberTitle: '¿Eliminar miembro del proyecto?',
      removeMemberDescription: 'Al hacer esto, eliminarás del proyecto al miembro seleccionado.',
      removeMemberCancel: 'Cancelar',
      removeMemberAccept: 'Eliminar miembro',
    },
    gantt: {
      task: 'Tareas',
      datetime: {
        months: [
          "Enero", "Febrero", "Marzo", "Abril",
          "Mayo", "Junio", "Julio", "Agosto",
          "Septiember", "Octubre", "Noviembre",
          "Diciembre"],
        weekdays: [
          'Domingo', 'Lunes', 'Martes', 'Miercoles',
          'Jueves', 'Viernes', 'Sabado'],
        shortMonths: [
          "Ene", "Feb", "Mar", "Abr",
          "May", "Jun", "Jul", "Ago",
          "Sep", "Oct", "Nov",
          "Dic"]
      },
      week: "Semana %W",
      start: 'Inicio: ',
      end: 'Fin: '
    },
    engine: {
      change: "Cambiar Engine",
      actually: "Actualmente usando",
      select: "Seleccione un engine",
      metric: "Métrica",
      change_button: "Cambiar",
      change_success: "Engine cambiada con éxito",
      metric_title: "Métricas de evaluación",
      coverage: "Cobertura",
    },
    myProfile: {
      previous: "Conocimientos previos",
      finals: "Conocimientos finales",
      instructions: "Responde el siguiente formulario según tu nivel de conocimiento en el área de desarrollo de software.",
      form: "Formulario",
      open: "Abierto hasta el",
      open_second: "Abierto desde el",
      de: "de",
      formClosed: "Formulario cerrado. Expiró el",
      formClosedSecond: "Formulario cerrado. El",
      edition: "se habilita para su edición"
    },
  },
};
