import React from "react";
import { createChatBotMessage } from 'react-chatbot-kit';
import Entregas from "./widgets/Entregas";
import EquipoDocente from "./widgets/EquipoDocente";
import Menu from "./widgets/Menu/Menu";
import Plantillas from "./widgets/Plantillas/Plantillas";
import Recomendacion from "./widgets/Recomendacion/Recomendacion";
import RecomendacionButtons from "./widgets/RecomendacionButtons/RecomendacionButtons";
import RecomendacionOptions from "./widgets/RecomendacionOptions/RecomendacionOptions";

const botName = 'ZmartBot';

const config = {
  initialMessages: [createChatBotMessage(
    `Escribe 'dame una recomendación' o elige una opción:`,
  {
    widget: "menu",
  })],
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: '#878fac',
    },
    chatButton: {
      backgroundColor: '#ff4729',
    },
  },
  customComponents: {
    header: () => <div className="react-chatbot-kit-chat-header" style={{backgroundColor: "#ff4729", color: "white"}}>ZmartBot Chat</div>,
  },
  widgets: [
    {
      widgetName: "entregas",
      widgetFunc: (props) => <Entregas {...props} />,
    },
    {
      widgetName: "equipoDocente",
      widgetFunc: (props) => <EquipoDocente {...props} />,
    },
    {
      widgetName: "menu",
      widgetFunc: (props) => <Menu {...props} />,
    },
    {
      widgetName: "plantillas",
      widgetFunc: (props) => <Plantillas {...props} />,
    },
    {
      widgetName: "recomendacion",
      widgetFunc: (props) => <Recomendacion {...props} />,
    },
    {
      widgetName: "recomendacionButtons",
      widgetFunc: (props) => <RecomendacionButtons {...props} />,
    },
    {
      widgetName: "recomendacionOptions",
      widgetFunc: (props) => <RecomendacionOptions {...props} />,
    }
  ]
};

export default config;