import {
  TOGGLE_EDIT_MODAL_BOARD,
  TOGGLE_DELETE_MODAL_BOARD,
} from '../../constants/modals/boards';

export default (state = {
  selectedBoard: '',
  boardEditing: false,
  boardDeleting: false,
}, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_EDIT_MODAL_BOARD: {
      const boardEditing = !state.boardEditing;
      const selectedBoard = payload.body.id ? payload.body.id : '';

      return {
        ...state,
        boardEditing,
        selectedBoard,
      };
    }
    case TOGGLE_DELETE_MODAL_BOARD: {
      const boardDeleting = !state.boardDeleting;
      const selectedBoard = payload.body.id ? payload.body.id : '';

      return {
        ...state,
        boardDeleting,
        selectedBoard,
      };
    }
    default: {
      return state;
    }
  }
};
