import { TOGGLE_CHATBOT_MODAL } from '../../actions/modals/chatbot';

const initialState = {
    isShown: false,
    defaultMessage: '',
    image: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_CHATBOT_MODAL:
            return {
                ...state,
                isShown: action.payload.isShown,
                defaultMessage: action.payload.defaultMessage,
                image: action.payload.image,
            };
        default:
            return state;
    }
};