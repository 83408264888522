import {
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR,
  CLEAR_NOTIFICATION,
} from '../constants/notification';

export const notifySuccess = message => ({
  type: NOTIFICATION_SUCCESS,
  payload: {
    body: {
      message,
    },
  },
});

export const notifyError = message => ({
  type: NOTIFICATION_ERROR,
  payload: {
    body: {
      message,
    },
  },
});

export const clearNotification = () => ({
  type: CLEAR_NOTIFICATION,
});
