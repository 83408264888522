import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Background from '../../assets/background.png';
import language from '../../constants/language';
import AppLogo from '../app/AppLogo';

const styles = {
  root: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundImage: `url(${Background})`,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  formTitle: {
    paddingLeft: '2.5%',
    paddingTop: '3%',
  },
  formInput: {
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingBottom: '5%',
  },
  welcome: {
    marginBottom: 20,
    color: 'white',
    fontSize: 20,
  },
  title: {
    color: 'white',
    fontSize: 49,
    fontWeight: 'bold',
  },
  logo: {
    color: '#ea5941',
    fontSize: 80,
    marginBottom: 10,
    marginRight: 10,
  },
};

const Container = ({ children, classes, selectedLanguage }) => (
  <div className={classes.root}>
    <Typography className={classes.welcome}>
      {language[selectedLanguage].login.welcomeTo}
    </Typography>
    <div style={{
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 60,
    }}
    >
      <AppLogo size="50px" className={classes.logo} />
      <Typography className={classes.title}>

        ZmartBoard
      </Typography>
    </div>
    { children }
  </div>
);

Container.propTypes = {
  children: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    selectedLanguage: state.user.language,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(Container));
