// Componente obtenido de: https://codesandbox.io/s/material-ui-image-upload-component-9s8u0?file=/src/ImageUpload.js
// imports the React Javascript Library
import React from "react";
//Card
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from '@material-ui/icons/Image';
import CloseIcon from '@material-ui/icons/Close';

//Tabs
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    marginBottom: 6,
  },
  button: {
    borderRadius: '50%',
  },
  input: {
    display: "none"
  },
  img: {
    width: 50,
    height: 50,
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%"
  }
});

class ImageUploadCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainState: 'initial', // initial
      imageUploaded: 0,
      selectedFile: "avatar.jpg",
      selectedFileObject: null,
    };
    this.handleUploadClick = this.handleUploadClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose = event => {
    this.setState({
      mainState: "initial", // initial
      imageUploaded: 0,
      selectedFile: "avatar.jpg",
      selectedFileObject: null,
    });
    this.props.setObjectFile('')
  }

  handleUploadClick = event => {
    console.log();
    let file = event.target.files[0];
    const reader = new FileReader();
    let url = reader.readAsDataURL(file);

    reader.onloadend = function(e) {
      this.setState({
        selectedFile: [reader.result]
      });
      this.props.setObjectFile(reader.result)
    }.bind(this);
    console.log(url); // Would see a path?

    this.setState({
      mainState: "uploaded",
      selectedFile: event.target.files[0],
      selectedFileObject: event.target.files[0],
      imageUploaded: 1
    });
  };

  renderInitialState() {
    const { classes, theme } = this.props;
    const { value } = this.state;

    return (
      <label htmlFor="contained-button-file">
        <Button variant="contained" component="span" className={classes.button}>
          <ImageIcon />
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            onChange={this.handleUploadClick}
          />
        </Button>
      </label>
    );
  }

  renderUploadedState() {
    const { classes, theme } = this.props;
    return (
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <img
            width="100%"
            className={classes.img}
            src={this.state.selectedFile}
          />
        </Grid>
        <label htmlFor="contained-button-file">
          <Button 
            variant="contained" 
            component="span" 
            className={classes.button}
            onClick={this.handleClose}
          >
            <CloseIcon />
          </Button>
        </label>
      </Grid>
    );
  }

  render() {
    const { classes, theme, objectFile } = this.props;
    if (objectFile) {
      this.state.mainState = "uploaded"
    } else {
      this.state.mainState = "initial"
    }

    return (
      <div className={classes.root}>
        {(this.state.mainState === "initial" && this.renderInitialState()) ||
          (this.state.mainState === "uploaded" && this.renderUploadedState())}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ImageUploadCard);