import React from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';
import Loadable from 'react-loadable';
import CircularProgress from '../components/lib/CircularProgress';
import PrivateComponent from './PrivateComponent';
import ProjectSidebar from './ProjectSidebar';
import NotFound from './notFound';

/* eslint-disable react/prop-types */
const Register = Loadable({
  loader: () => import('./register'),
  loading() {
    return <CircularProgress />;
  },
});

const Login = Loadable({
  loader: () => import('./login'),
  loading() {
    return <CircularProgress />;
  },
});

const Welcome = Loadable({
  loader: () => import('./welcome'),
  loading() {
    return <CircularProgress />;
  },
});

const Logout = Loadable({
  loader: () => import('./logout'),
  loading() {
    return <CircularProgress />;
  },
});

const Home = Loadable({
  loader: () => import('./home'),
  loading() {
    return <CircularProgress />;
  },
});

const Mailer = Loadable({
  loader: () => import('./mailer'),
  loading() {
    return <CircularProgress />;
  },
});

const Project = Loadable({
  loader: () => import('./project'),
  loading() {
    return <CircularProgress />;
  },
});

const Report = Loadable({
  loader: () => import('./report'),
  loading() {
    return <CircularProgress />;
  },
});

const UserReport = Loadable({
  loader: () => import('./userReport'),
  loading() {
    return <CircularProgress />;
  },
});

const HoursReport = Loadable({
  loader: () => import('./hoursReport'),
  loading() {
    return <CircularProgress />;
  },
});

const TasksReport = Loadable({
  loader: () => import('./tasksReport'),
  loading() {
    return <CircularProgress />;
  },
});

const TimelineReport = Loadable({
  loader: () => import('./timelineReport'),
  loading() {
    return <CircularProgress />;
  },
});

const BurnupReport = Loadable({
  loader: () => import('./burnupReport'),
  loading() {
    return <CircularProgress />;
  },
});

const BurndownReport = Loadable({
  loader: () => import('./burndownReport'),
  loading() {
    return <CircularProgress />;
  },
});

const PRReport = Loadable({
  loader: () => import('./prReport'),
  loading() {
    return <CircularProgress />;
  },
});

const LeadReport = Loadable({
  loader: () => import('./leadReport'),
  loading() {
    return <CircularProgress />;
  },
});

const CommitReport = Loadable({
  loader: () => import('./commitReport'),
  loading() {
    return <CircularProgress />;
  },
});

const AverageTaskCompletionReport = Loadable({
  loader: () => import('./averageTaskCompletionReport'),
  loading() {
    return <CircularProgress />;
  },
});

const EstimatedHoursVariationReport = Loadable({
  loader: () => import('./estimatedHoursVariationReport'),
  loading() {
    return <CircularProgress />;
  },
});

const TaskProgressReport = Loadable({
  loader: () => import('./taskProgressReport'),
  loading() {
    return <CircularProgress />;
  },
});

const WorkloadReport = Loadable({
  loader: () => import('./workloadReport'),
  loading() {
    return <CircularProgress />;
  },
});

const Milestone = Loadable({
  loader: () => import('./milestone'),
  loading() {
    return <CircularProgress />;
  },
});

const MailerAuth = Loadable({
  loader: () => import('./mailerAuth'),
  loading() {
    return <CircularProgress />;
  },
});

const ForgotPass = Loadable({
  loader: () => import('./forgotPass'),
  loading() {
    return <CircularProgress />;
  },
});

const resetPass = Loadable({
  loader: () => import('./forgotPass/resetPass'),
  loading() {
    return <CircularProgress />;
  },
});

const Github = Loadable({
  loader: () => import('./github'),
  loading() {
    return <CircularProgress />;
  },
});

const Settings = Loadable({
  loader: () => import('./settings'),
  loading() {
    return <CircularProgress />;
  },
});

const Dashboard = Loadable({
  loader: () => import('./dashboard'),
  loading() {
    return <CircularProgress />;
  },
});

// const UserInfo = Loadable({
//   loader: () => import('./userinfo'),
//   loading() {
//     return <CircularProgress />;
//    },
//  });

const ResendMail = Loadable({
  loader: () => import('./resendPass'),
  loading() {
    return <CircularProgress />;
  },
});

const EvaluationModule = Loadable({
  loader: () => import('./evaluation'),
  loading() {
    return <CircularProgress />;
  },
});

const LessonsModule = Loadable({
  loader: () => import('./lessons'),
  loading() {
    return <CircularProgress />;
  },
});
const standUpModule = Loadable({
  loader: () => import('./standUpReports'),
  loading() {
    return <CircularProgress />;
  },
});
const myProfile = Loadable({
  loader: () => import('./myprofile'),
  loading() {
    return <CircularProgress />;
  },
});

// const Evaluation = Loadable({
//   loader: () => import('./evaluation/evaluationView'),
//   loading() {
//     return <CircularProgress />;
//   },
// });

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <PrivateComponent>
          {
            rest.path.includes('/project/') ? (
              <ProjectSidebar path={rest.path}>
                <Component {...props} />
              </ProjectSidebar>
            ) : <Component {...props} />
          }
        </PrivateComponent>
      )}
    />
  );
};

export default () => (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route exact path="/register" component={Register} />
    <Route exact path="/login/:username?" component={Login} />
    <Route exact path="/logout" component={Logout} />
    <Route exact path="/forgot" component={ForgotPass} />
    <Route exact path="/resend" component={ResendMail} />
    <Route exact path="/api/auth/confirmation" component={MailerAuth} />
    <Route exact path="/reset_password/:token" component={resetPass} />
    <Route exact path="/mail" component={Mailer} />
    <Route exact path="/github/:id" component={Github} />
    {/* <Route exact path="/userinfo" component={UserInfo} /> */}
    <PrivateRoute exact path="/welcome" component={Welcome} />
    <PrivateRoute exact path="/settings" component={Settings} />
    <PrivateRoute exact path="/dashboard" component={Dashboard} />
    <PrivateRoute exact path="/myprofile" component={myProfile} />
    <PrivateRoute exact path="/home" component={Home} />
    <PrivateRoute exact path="/project/:id" component={Project} />
    <PrivateRoute exact path="/project/:id/milestone" component={Milestone} />
    <PrivateRoute exact path="/project/:id/report" component={Report} />
    <PrivateRoute exact path="/project/:id/report/user/:start_date/:end_date" component={UserReport} />
    <PrivateRoute exact path="/project/:id/report/hours/:start_date/:end_date/:filter_option" component={HoursReport} />
    <PrivateRoute exact path="/project/:id/report/tasks/:start_date/:end_date/:user_id" component={TasksReport} />
    <PrivateRoute exact path="/project/:id/report/timeline/:start_date/:end_date" component={TimelineReport} />
    <PrivateRoute exact path="/project/:id/report/burnup/:milestone_id" component={BurnupReport} />
    <PrivateRoute exact path="/project/:id/report/burndown/:milestone_id" component={BurndownReport} />
    <PrivateRoute exact path="/project/:id/report/pullrequests" component={PRReport} />
    <PrivateRoute exact path="/project/:id/report/lead" component={LeadReport} />
    <PrivateRoute exact path="/project/:id/report/commits/:start_date/:end_date/:user_id/:selected_repo_id" component={CommitReport} />
    <PrivateRoute exact path="/project/:id/report/averagetaskcompletion/:milestone_id" component={AverageTaskCompletionReport} />
    <PrivateRoute exact path="/project/:id/report/estimatedhoursvariation/:milestone_id" component={EstimatedHoursVariationReport} />
    <PrivateRoute exact path="/project/:id/report/taskprogress/:start_date/:end_date/:board_id" component={TaskProgressReport} />
    <PrivateRoute exact path="/project/:id/report/workload/:start_date/:end_date/:board_id" component={WorkloadReport} />
    <PrivateRoute exact path="/project/:id/lessons" component={LessonsModule} />
    <PrivateRoute exact path="/project/:id/lessons/:lesson_id" component={LessonsModule} />
    <PrivateRoute exact path="/project/:id/standupreports" component={standUpModule} />
    <PrivateRoute exact path="/project/:id/standupreports/:lesson_id" component={standUpModule} />
    <Route component={NotFound} />
  </Switch>
);

// Para agregar la ruta de evaluations (<PrivateRoute exact path="/project/:id/evaluation" component={EvaluationModule} />)