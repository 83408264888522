import Pusher from 'pusher-js';
import { mergeMap, mapTo, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';

import {
  PUSHER_CONNECT,
  PUSHER_CONNECTION,
  PUSHER_SUBSCRIBE_CHANNEL,
  PUSHER_UNSUBSCRIBE_CHANNEL,
  PUSHER_MESSAGE_RECEIVED,
} from '../../../constants/pusher';

const APP_KEY = '32b1241ed4fa4323e3b4';
const APP_ID = '613183';
const SECRET = 'fd63fd1bddba8e01c91a';
const CLUSTER = 'mt1';

let pusherClient;

// constants used in backend api to send pusher events
const taskEvents = [
  'PUSHER_CREATE_TASK',
  'PUSHER_DELETE_TASK',
  'PUSHER_UPDATE_TASK',
];

const columnEvents = [
  'PUSHER_CREATE_COLUMN',
  'PUSHER_DELETE_COLUMN',
  'PUSHER_UPDATE_COLUMN',
];

const orderEvents = [
  'PUSHER_MOVE_TASK',
  'PUSHER_MOVE_COLUMN',
  // 'PUSHER_REFRESH_TASKS',
  // 'PUSHER_REFRESH_COLUMN',
];

const commentEvents = [
  'PUSHER_CREATE_TASK_COMMENT',
  'PUSHER_UPDATE_TASK_COMMENT',
  'PUSHER_DELETE_TASK_COMMENT',
];

const labelsEvents = [
  'PUSHER_ADD_LABEL',
  'PUSHER_REMOVE_LABEL',
  'PUSHER_UPDATE_LABEL',
];

const events = [
  ...taskEvents,
  ...columnEvents,
  ...orderEvents,
  ...commentEvents,
  ...labelsEvents,
];

const connectPusher = action$ => action$.pipe(
  ofType(PUSHER_CONNECT),
  mergeMap(action => Observable.create((observer) => {
    pusherClient = new Pusher(APP_KEY, {
      appId: APP_ID,
      secret: SECRET,
      cluster: CLUSTER,
      forceTLS: true,
      // encrypted: true,
    });

    pusherClient.connection.bind('connected', () => {
      observer.next({ type: `${PUSHER_CONNECTION}_SUCCESS`, id: action.id });
    });

    pusherClient.connection.bind('error', (error) => {
      observer.next({ type: `${PUSHER_CONNECTION}_ERROR`, error });
    });
  })),
);

const connectionSuccess = action$ => action$.pipe(
  ofType(`${PUSHER_CONNECTION}_SUCCESS`),
  mergeMap(action => Observable.create((observer) => {
    observer.next({ type: PUSHER_SUBSCRIBE_CHANNEL, channel: action.id });
  })),
);

const subscribeChannel = action$ => action$.pipe(
  ofType(PUSHER_SUBSCRIBE_CHANNEL),
  mergeMap(action => Observable.create((observer) => {
    const channel = pusherClient.subscribe(action.channel);
    events.forEach((e) => {
      channel.bind(e, (message) => {
        observer.next({ type: PUSHER_MESSAGE_RECEIVED, message, channel: action.channel });
      });
    });
  })),
);

const unsubscribeChannel = action$ => action$.pipe(
  ofType(PUSHER_UNSUBSCRIBE_CHANNEL),
  mapTo({ type: `${PUSHER_UNSUBSCRIBE_CHANNEL}_SUCCESS`, channel: action$.id }),
);

const recieveMessageChannel = (action$, state$) => action$.pipe(
  ofType(PUSHER_MESSAGE_RECEIVED),
  filter(action => state$.value.auth.id !== action.message.user_id),
  mergeMap(action => Observable.create((observer) => {
    const { type, obj } = action.message;
    observer.next({ type, payload: obj });
  })),
);

export default combineEpics(
  connectPusher,
  connectionSuccess,
  subscribeChannel,
  unsubscribeChannel,
  recieveMessageChannel,
);
