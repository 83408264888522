import {
  TOGGLE_ASSIGN_MODAL_TASK,
  TOGGLE_LABEL_MODAL_TASK,
  TOGGLE_EDIT_MODAL_TASK,
  TOGGLE_CREATE_MODAL_TASK,
  TOGGLE_CREATE_MODAL_COLUMN,
  START_DRAG,
  TOGGLE_DROPDOWN_TASK,
  TOGGLE_DROPDOWN_COLUMN,
  TOGGLE_CREATE_MODAL_BOARD,
  TOGGLE_REPORT_MODAL_TASK,
} from '../constants/board';

import {
  FETCH_DELETE_TASK,
} from '../constants/api/tasks';

import {
  SELECT_BOARD,
  SELECT_BOARD_GANTT,
  HIDE_ALL_BOARD,
} from '../constants/user';

export default (state = {
  columnCreating: false,
  showDropDownTask: false,
  showDropDownColumn: false,
  showAssignTaskForm: false,
  showLabelTaskForm: false,
  showReportTaskForm: false,
  showEditTaskForm: false,
  showCreateTaskForm: false,
  showCreateBoardForm: false,
  selectedTask: '',
  selectedColumn: '',
  selectedRole: '',
}, action) => {
  const { type, payload } = action;

  switch (type) {
    case HIDE_ALL_BOARD: {
      return {
        ...state,
        columnCreating: false,
        showDropDownTask: false,
        showDropDownColumn: false,
        showAssignTaskForm: false,
        showLabelTaskForm: false,
        showEditTaskForm: false,
        showCreateTaskForm: false,
        showCreateBoardForm: false,
        selectedTask: '',
        selectedColumn: '',
      };
    }
    case SELECT_BOARD: {
      return {
        ...state,
        columnCreating: false,
        showDropDownTask: false,
        showDropDownColumn: false,
        showAssignTaskForm: false,
        showLabelTaskForm: false,
        showEditTaskForm: false,
        showCreateTaskForm: false,
        showCreateBoardForm: false,
        selectedTask: '',
        selectedColumn: '',
      };
    }
    case SELECT_BOARD_GANTT: {
      return {
        ...state,
        columnCreating: false,
        showDropDownTask: false,
        showDropDownColumn: false,
        showAssignTaskForm: false,
        showLabelTaskForm: false,
        showEditTaskForm: false,
        showCreateTaskForm: false,
        showCreateBoardForm: false,
        selectedTask: '',
        selectedColumn: '',
      };
    }
    case TOGGLE_ASSIGN_MODAL_TASK: {
      const showAssignTaskForm = !state.showAssignTaskForm;
      const selectedTask = payload.body.id ? payload.body.id : '';
      return {
        ...state,
        selectedTask,
        showAssignTaskForm,
        showDropDownTask: false,
        showDropDownColumn: false,
        showEditTaskForm: false,
        showCreateTaskForm: false,
        showCreateBoardForm: false,
        columnCreating: false,
      };
    }
    case TOGGLE_LABEL_MODAL_TASK: {
      const showLabelTaskForm = !state.showLabelTaskForm;
      const selectedTask = payload.body.id ? payload.body.id : '';
      return {
        ...state,
        selectedTask,
        showLabelTaskForm,
        showDropDownTask: false,
        showDropDownColumn: false,
        showEditTaskForm: false,
        showCreateTaskForm: false,
        showCreateBoardForm: false,
        columnCreating: false,
      };
    }
    case TOGGLE_REPORT_MODAL_TASK: {
      const showReportTaskForm = !state.showReportTaskForm;
      const selectedTask = payload.body.id ? payload.body.id : '';
      return {
        ...state,
        selectedTask,
        showReportTaskForm,
        showDropDownTask: false,
        showDropDownColumn: false,
        showEditTaskForm: false,
        showCreateTaskForm: false,
        showCreateBoardForm: false,
        columnCreating: false,
      };
    }
    case TOGGLE_EDIT_MODAL_TASK: {
      const showEditTaskForm = !state.showEditTaskForm;
      const selectedTask = payload.body.body ? payload.body.body.id : '';
      const selectedRole = payload.body.body ? payload.body.body.role : '';
      return {
        ...state,
        selectedTask,
        selectedRole,
        showEditTaskForm,
        showDropDownTask: false,
        showDropDownColumn: false,
        showAssignTaskForm: false,
        showLabelTaskForm: false,
        showCreateTaskForm: false,
        showCreateBoardForm: false,
        columnCreating: false,
      };
    }
    case TOGGLE_CREATE_MODAL_TASK: {
      const showCreateTaskForm = !state.showCreateTaskForm;
      const selectedColumn = payload.body.id ? payload.body.id : '';
      return {
        ...state,
        selectedColumn,
        showCreateTaskForm,
        showDropDownTask: false,
        showDropDownColumn: false,
        showEditTaskForm: false,
        showAssignTaskForm: false,
        showLabelTaskForm: false,
        showCreateBoardForm: false,
        columnCreating: false,
      };
    }
    case TOGGLE_CREATE_MODAL_COLUMN: {
      const columnCreating = !state.columnCreating;
      return {
        ...state,
        columnCreating,
        showDropDownTask: false,
        showDropDownColumn: false,
        showCreateTaskForm: false,
        showEditTaskForm: false,
        showAssignTaskForm: false,
        showLabelTaskForm: false,
        showCreateBoardForm: false,
      };
    }
    case START_DRAG: {
      return {
        ...state,
        showDropDownTask: false,
        showDropDownColumn: false,
        columnCreating: false,
        showAssignTaskForm: false,
        showLabelTaskForm: false,
        showEditTaskForm: false,
        showCreateTaskForm: false,
        showCreateBoardForm: false,
        selectedTask: '',
        selectedColumn: '',
      };
    }
    case TOGGLE_DROPDOWN_TASK: {
      const showDropDownTask = !state.showDropDownTask;
      const selectedTask = payload.body.id ? payload.body.id : '';
      return {
        ...state,
        selectedTask,
        showDropDownTask,
        showDropDownColumn: false,
        columnCreating: false,
        showAssignTaskForm: false,
        showLabelTaskForm: false,
        showEditTaskForm: true,
        showCreateTaskForm: false,
        showCreateBoardForm: false,
        selectedColumn: '',
      };
    }
    case `${FETCH_DELETE_TASK}_REQUEST`: {
      return {
        ...state,
        showEditTaskForm: false,
        showDropDownTask: false,
      };
    }
    case TOGGLE_DROPDOWN_COLUMN: {
      const showDropDownColumn = !state.showDropDownColumn;
      const selectedColumn = payload.body.id ? payload.body.id : '';
      return {
        ...state,
        selectedColumn,
        showDropDownColumn,
        showDropDownTask: false,
        columnCreating: false,
        showAssignTaskForm: false,
        showLabelTaskForm: false,
        showEditTaskForm: false,
        showCreateTaskForm: false,
        showCreateBoardForm: false,
        selectedTask: '',
      };
    }
    case TOGGLE_CREATE_MODAL_BOARD: {
      const showCreateBoardForm = !state.showCreateBoardForm;
      return {
        ...state,
        showCreateBoardForm,
        showDropDownColumn: false,
        showDropDownTask: false,
        columnCreating: false,
        showAssignTaskForm: false,
        showLabelTaskForm: false,
        showEditTaskForm: false,
        showCreateTaskForm: false,
        selectedColumn: '',
        selectedTask: '',
      };
    }
    case 'PUSHER_DELETE_TASK': {
      return {
        ...state,
        showDropDownTask: false,
        showAssignTaskForm: false,
        showLabelTaskForm: false,
        showReportTaskForm: false,
        showEditTaskForm: false,
        selectedTask: '',
      };
    }
    default: {
      return state;
    }
  }
};
