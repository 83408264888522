import moment from 'moment';
import {
  TOGGLE_ARCHIVE_MODAL_PROJECT,
} from '../constants/modals/archived';

import {
  TOGGLE_ASSIGN_MODAL_REPO,
  ARCHIVE_SEARCH,
  REPORT_HOURS,
  USERS_HOURS,
  TRAZABILITY_HOURS,
  TIMELINE_HOURS, TOGGLE_FILTER,
} from '../constants/project';

import {
  FETCH_AUTH_LOGIN,
  FETCH_GOOGLE_AUTH,
} from '../constants/api/auth';

export default (state = {
  showAssignRepo: false,
  selectedProject: '',
  search: '',
  reporthoursStartDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
  reporthoursEndDate: moment().format('YYYY-MM-DD'),
  reporthoursFilterOption: 'day',
  userhoursStarDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
  userhoursEndDate: moment().format('YYYY-MM-DD'),
  timelinehoursStarDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
  timelinehoursEndDate: moment().format('YYYY-MM-DD'),
  trazabilityhoursUser: '',
  trazabilityhoursStarDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
  trazabilityhoursEndDate: moment().format('YYYY-MM-DD'),
  trazabilityhoursRepository: '',
  filter: false,
}, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_ASSIGN_MODAL_REPO: {
      const showAssignRepo = !state.showAssignRepo;
      const selectedProject = payload.body.id ? payload.body.id : '';
      return {
        ...state,
        selectedProject,
        showAssignRepo,
      };
    }
    case ARCHIVE_SEARCH: {
      return {
        ...state,
        search: payload.body.text ? payload.body.text : '',
      };
    }
    case TOGGLE_ARCHIVE_MODAL_PROJECT: {
      return {
        ...state,
        search: '',
      };
    }
    case REPORT_HOURS: {
      return {
        ...state,
        reporthoursStartDate: payload.body.startDate,
        reporthoursEndDate: payload.body.endDate,
        reporthoursFilterOption: payload.body.filterOption,
      };
    }
    case USERS_HOURS: {
      return {
        ...state,
        userhoursStarDate: payload.body.startDate,
        userhoursEndDate: payload.body.endDate,
      };
    }
    case TIMELINE_HOURS: {
      return {
        ...state,
        timelinehoursStarDate: payload.body.startDate,
        timelinehoursEndDate: payload.body.endDate,
      };
    }
    case TRAZABILITY_HOURS: {
      return {
        ...state,
        trazabilityhoursUser: payload.body.user,
        trazabilityhoursStarDate: payload.body.startDate,
        trazabilityhoursEndDate: payload.body.endDate,
        trazabilityhoursRepository: payload.body.selectedRepo,
      };
    }
    case `${FETCH_AUTH_LOGIN}_SUCCESS`: {
      const userId = payload.body.data.id;
      return {
        ...state,
        trazabilityhoursUser: userId,
      };
    }
    case `${FETCH_GOOGLE_AUTH}_SUCCESS`: {
      const userId = payload.body.data.id;
      return {
        ...state,
        trazabilityhoursUser: userId,
      };
    }
    case TOGGLE_FILTER: {
      const filter = !state.filter;
      return {
        ...state,
        filter,
      };
    }
    default: {
      return state;
    }
  }
};
